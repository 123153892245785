.widget-cont{
    >*{
        width:1200px;
        max-width:90vw;
        margin:auto;
        background: inherit;
        color:inherit;
    }
    h2{
        background:black !important;
    }
    .eds-modal{
        background:blue !important;
    }
}