@import "@/style/variables.scss";

div.section-submit-success{
    background: $offer-white;
    p{
        max-width: 600px;
        margin-bottom: 60px;
    }
    div.buttons{
        
        button{
            width:200px;
            margin:0 20px;
            &:nth-child(2){
                background: rgb(23, 23, 206);
                color:white;
            }
        }
    }
}