.e-button-cont{
    position: relative;
    button{
        input{
            font-weight: inherit;
        }
        .e-textarea{
          display: flex;
        }
    }
}

.e-button{
    opacity: 1;
    letter-spacing: .15em;
    box-shadow: none;
    &:hover{
        transform: unset;
    }
    &.button-set-solid{
        background: grey;
        border:none;
    }
    &.button-set-box{
    background: transparent !important;
    color: inherit;
    font-size: 1.3rem;
    border: 2px solid #aae1cc;
    border-radius: 0;
    border-width: 2px;
    border-style: solid;
    }
    &.hover-set-sandwich{
      position: relative;
      box-shadow: unset;
      &:before{
        position: absolute;
        background:white;
        content: "";
        height: 3px;
        width:10px;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:after{
        position: absolute;
        background:white;
        content: "";
        height: 3px;
        width:10px;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:hover{
        background: none !important;
        box-shadow: unset;
        letter-spacing: .3em;
        &::after,&::before{
          width:100%;
          transition: all 1s;
        }
      }

    }
    &.hover-set-slide {
        transition: all .3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 3;
          border-radius: inherit;
        }
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 100%;
          transition: all .3s;
          border-radius: inherit;
          z-index: 4;          
          background: #ffffff78;
        }
        &:hover {
          &:before {
            width: 100%;
          }
        }
      }
      &.hover-set-bounce{
        transition: all 500ms;
        &:hover{
          transform: translateY(-3px);
        }
        &:active{
          transform: translateY(3px);
        }
      }
      
}