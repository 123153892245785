.modal.page-status{
    padding:0;
    background-color: #fcfefe;
    .modal-content{
        max-height: 90vh;
        overflow-x: hidden;
        overflow: hidden;
        overflow-y: scroll;
        header{
            background:rgb(250, 250, 251);
            h1{
                font-size: 1.3rem;
                font-weight: 500;
                margin:1em;
                text-transform: uppercase;
            }
        }
        .subscription-box-cont{
            margin:2em;
            margin-top:.5rem;
            .status{
                margin-bottom: 1em;
            }
            h2{
                font-size: 1.2rem;
                margin:0em 0 1em 0;
            }
            p{
                margin-bottom: 1em;
            }
            .promo-input-cont{
                border:none;
                padding:0;
                margin-bottom: 1rem;
                input{
                    border:5px solid rgb(222, 215, 215);
                    box-shadow: unset;
                    border-right:none;
                    border-radius: 5px 0 0 5px;
                    box-shadow: 1px 1px 2px grey;
                }
                button{
                    padding: 1.05rem;
                    border-radius: 0px;
                    transform: translateY(-1px);
                    color:black;
                }
            }
        }
    }

}

.modal-overlay{
    .modal.sidebar.page-status{
        >button.close-modal-button{
            padding:.2rem .7rem;
        }
        header{
            padding:0
        }
        overflow: scroll;
        max-height: unset;
        @media screen and (max-width:500px) {
            position: absolute;
            top:0;
            left:0;
            height: 100vh;

        }
        .modal-content{
            max-height: unset;
            height: unset;
            padding:0;
            padding-bottom: 60px;
        }
    }
}

.modal.page-status .close-modal-button{
    z-index: 400;
}

.flex-row{
    margin-top:100px;
    display: flex;
    align-items: center;
    
}

.page-status h5{
    font-size: 1.2rem;
    font-weight: 400;
    font-style: italic;
    margin:0;
    margin-left: 20px;
}



.subscription-box-cont h1{
    font-size: 1.5rem;
    font-weight: 500;
    color:rgb(63, 63, 63);
}

.confirmation button{
    margin-top:0 !important;
}

@media screen and (max-width:600px) {
    .page-status .modal-content header{
        background:none;

    }
    .page-status .modal-content header>h1{
        margin-top:.3rem;
        margin-bottom:1.5rem;
        margin-left: 1rem;
    }
}