.expander-btn{
    background: none;
    box-shadow: none;
    position: relative;
}

.expander-btn:hover{
    
}

.expander-btn div:before{
    display: block;
    font-size: 1.5em; /* adjust font size to your liking */
    content:"";
    background-color: grey;
    width: 20px;
    height: 3px;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top:50%;
    left:50%;
    translate: -50%;
    transform: rotate(0deg);
}

.expander-btn div:after{
    display: block;
    font-size: 1.5em; /* adjust font size to your liking */
    content:"";
    background-color: grey;
    width: 20px;
    height: 2px;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top:50%;
    left:50%;
    translate: -50%;
    transform: rotate(90deg);
}

.expander-btn.expanded div:after{
    transform: rotate(0deg);
}