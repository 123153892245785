main.standard{

    
    .section-dev-btn.blue{
        z-index: 200;
        color: white;
        background: #1b84d7;
        border-radius: 1.5rem;
        padding: 6px 10px;
    margin: 0.5rem;
    position: absolute;
    top:0;
    left:50%;
    transform: translateX(-50%);
}

.section-submit-confirmation{
    color:#111111;
    h2{
        margin-bottom: 2em;
    }
    p{
        font-size: 1rem;
        margin: 1em 0;
    }
    footer{
        padding-top: 40px;
        button{
            margin-right:2em;
        }
    }
}
}