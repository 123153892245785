.sub-section{
    position: relative;
    .delete-circle{
        display: none;
    }
    &:hover{
        border: 2px dashed black;
        >.delete-circle{
            scale:.7;
            display: unset;
            top:unset;
            right:unset;
            bottom:0;
            left:50%;
            transform: translate(-50%,20%);
        }
    }
} 