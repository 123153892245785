@import "@/style/mixins.scss";
@import "@/style/variables.scss";

.spotlight-page{
    @include full-page;

    ul{
        display:grid;
        grid-template-columns:1fr 1fr;
        list-style: none;
        scale:40%;
        li{
            scale: unset;
        }
        .preview-cont{
            max-height:600px;
            overflow: scroll;
            header{
                display: none;
            }
        }
        
    }   
    header.main-header{
        // position: unset;
        // background: $dark-blue;
        
    } 
    .spotlight-cont{
        max-width: 1100px;
        display:grid;
        grid-template-columns:1fr 1fr;
        width: fit-content;
        margin:auto;
        margin-top: 50px;
        background: linear-gradient(0deg, #143292, #131759);
        >div:first-child{
            justify-self: center;
            align-self: center;
            transform: translate(0px,-55px);
            font-size: 50px;
            color:rgb(249, 254, 164);
            padding:50px;
            max-width: 425px;
            @media screen and (max-width:800px) {
                padding:35px;
            }
            h4{
                margin-top: 1em;
                font-size: 1.6rem;
                color:$off-white;
            }
        }
        div.call-to-action{
            transform: translate(0px,-135px);
            color:white;
            @include flex-down;
            button{
                margin-top:20px;
            }
            padding-bottom: 40px;

        }
        @media screen and (max-width:900px) {
            @include flex-down;
            >*{
                transform: unset !important;
            }
        }
    }
    main{
        background: linear-gradient(0deg, #143292, #131759);

    }
}