.card{
    margin: 1rem;
    width: 250px;
    height: 350px;
    background: #f2f2f2;
    border-radius: 15px;
    display: relative;
    padding:1rem
}

.card.assisted{
    background:rgb(201, 218, 232);
}