main.standard section.section.image-and-text {
    --overlayOpacity:0;
}

section.section.image-and-text.border-side__layout{
        display:grid;
        grid-template-columns: 1fr 1fr;
        align-items: start;
        grid-column-gap: 3rem;
        padding-top:50px;
        padding-bottom: 50px;
        grid-template-rows: 7rem auto;
        .grid-row-1-3{
            grid-row:1/3;
        }
        .section-heading{
            margin-top:0;
            margin-bottom: 1rem;
            text-align: left;
            width:unset;
            justify-self: flex-start;
            grid-column: 2;
            max-width: 30vw;
            @media screen and (max-width:500px) {
                max-width: unset;
            }
            input{
                max-width: 30vw;
                text-align: left;
            }
        }
        img,.cover{
            justify-self: flex-end;
            align-self: center;
            grid-row:1/3;
            max-width: 500px;
            width:unset;
        }
        .text-cont{
            margin-top: 0px;
            font-size:1.4rem;
            max-width:600px;
            grid-column: 2;
            margin-bottom: 0;
        }
        .edit-img-cont{
            max-width: 500px;
        }
        @media screen and (max-width:500px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top:0px;
            h2.section-heading{
                margin-top: 1em;
                margin-bottom: 1em;
            }
            img{
                width: 90vw;
                max-width: unset;
            }
            div.text-cont{
                margin-top: 1em;
                margin-bottom: 0rem;
                width: 90vw;
                font-size: 1.2rem;
                // text-align: center;
                
            }
        }
}


.image-and-text .text-cont {
    transform: unset;
    position: unset;
    margin-left: unset;
    margin-top: 40px;
    width: 80%;
    font-size: clamp(1rem,2vw,1.5rem);
    max-width: 800px;
    margin-bottom: 4rem;

}



.image-and-text img{
    width: 100vw;
    max-width: 700px;
}

.image-and-text .text-cont p,.image-and-text .text-cont .p{
    font-size: inherit;
    max-width: 800px;
    background: none;
    font-family: inherit;
    margin-bottom: 1em;
}

.image-and-text .edit-img-cont{
    width: 100vw;
    max-width: 700px;
    min-height: 100px;
    background: none;
}

.edit-img-cont .delete-circle{
    margin:0 !important;
}

@media screen and (max-width:600px) {
    .image-and-text{
        width:unset;
        padding:unset
    }
    .image-and-text img{
        width: 100vw;
    }
    .image-and-text .edit-img-cont{
        width: 100vw;
    }
    .image-and-text .edit-img-cont input{
        width:320px;
    }
    .image-and-text .delete-circle {
        background: #ec0f0f;
        position: absolute;
        top: unset;
        right: 40px;
        bottom: 20px;
        transform: unset;
        border-radius: 50%;
        color: white;
        border-radius: 5px;
        color: transparent !important;
    }
    .image-and-text .delete-circle::before{
        content: "Click Here to Replace Image";
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        color: white;
    }
}

.img-size-set-large{
    @media screen and (min-width:800px) {
        width:clamp(300px,40vw,600px) !important;
    }
    @media screen and (max-width:400px) {
        width: clamp(300px, 90vw, 400px) !important;    }

}