.call-to-action-heading{
    margin-top:1.7em;
    font-weight: 400;
    text-transform: uppercase;
}

button.call-to-action-btn{
    padding:1.3em 2.2em;
    text-transform: uppercase;
    font-size: 1rem;
    background-color: var(--btn-primary);
    color:var(--light-text);
    font-weight: 550;
    letter-spacing: 0.2em;
}

button.call-to-action-btn:hover{
    background-color: var(--btn-primary-hover);
}