.confirmation-modal{
    padding: 10px !important;
}

.confirmation-modal .modal-content{
    width: 400px;
    max-width: 70vw;

}

.confirmation-modal .close-modal-button{
display: none;
}

.confirmation-modal .modal-content>div{
    padding:2rem;
    padding-bottom:.5rem;
    font-size: 1.35rem;
}

.confirmation-modal .modal-content .btn-cont{
    margin-top:60px;
    margin-bottom:20px;
    display: flex;
    justify-content: space-between;
}