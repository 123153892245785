@import "../../style/mixins.scss";

.edit-click-action-open{
    font-family: sans-serif;
    position: absolute;
    bottom:0;
    transform: translate(-50%,120%);
    left:50%;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.edit-click-action-modal{
    @include standard-modal;
    @include center-modal;
    @include save-modal;
    .input-cont{
        @include standard-input-cont
    }
}