article.help-topic-listing{
    margin:40px 0;
    header{
        background: unset;
        display: block;
        padding-bottom: 15px;
        h4{
            margin-top: 5px;
            font-weight: 500;
            font-style: italic;
        }
    }
    div.content{
        margin-left:20px;
        p{
            margin:.8rem 0;
        }
        ul{
            margin-left:20px;
            li{
                margin:.3rem 0;
            }
        }
    }
}