.layout-modal .modal-content .picker-cont{
    padding-top: .8rem;
    padding-bottom:0rem;
    margin-bottom: -1rem;
}
.layout-modal .modal-content .picker-cont>.input-cont{
    margin-bottom:1rem;
}

.picker-cont>.input-cont select{
    margin-bottom: 0;
    font-family: inherit;
    margin-left: 1rem;
    padding:0.3rem;
}

.flexbox-fix:nth-of-type(2)>div:first-child>div:first-child{
    height: 40px !important;
}

.flexbox-fix:nth-of-type(2)>div:first-child>div:nth-child(2){
    display: none;
}

.hue-horizontal>div:first-of-type>div:first-child{
    height: 40px !important;
    width: 10px !important;
    cursor: grab;
}

.picker-cont .circle-picker>span{
    width:unset;
}

.sketch-picker,.circle-picker,.square-picker{
    margin:auto
}