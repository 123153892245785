main.confirm-accept-submission-main{
    position: fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background-color: rgb(250, 248, 248);
    z-index: 10;
    display: flex;
    flex-direction: column;
    h2{
        margin:1em;
        margin-bottom: 0;
    }
    div.verbage{
        padding:2rem;
        padding-top: .5em;
        color:grey;
        p{
            max-width: 700px;
            margin: auto;
            margin-bottom: 1em;
        }
    }
    .section-submission-preview{
        overflow-y: scroll;
        // border: 3px rgb(75, 73, 73) dashed;
        margin: 1.5rem;
        box-shadow: 2px 2px 10px  black;
        border-radius: 5px;
        .owners-nav{
            .accept-submission-btn{
                display: none;
            }
        }
        .dynamic-nav-header{
            display: none;
            
        }
    }
    footer{
        padding:1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        button{
            margin: auto 2rem;
        }
    }

}