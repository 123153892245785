.image-link-container{
    cursor: pointer;
    position: relative;
    width:fit-content;
    .subheading_class{
        margin-top:1em;
        font-size: 2rem;
        position: absolute;
        top: 0;
        left: 50%;
        color: inherit;
        width: 90%;
        transform: translateX(-50%);
        text-align: center;
    }
    .e-link-cont{
        &.position-set-overlap{

            position: absolute;
            transform: translateX(-50%);
            left:50%;
        }
        bottom:00px;
        width:100%;
        padding:1rem;
        background:grey;
        a{
            color:inherit;
            text-decoration: none;
        }
    }
    .edit-img-cont{
        .delete-circle{
            top:unset;
            bottom:0;
            scale: .7;
            right:20px;
        }

    }    
}