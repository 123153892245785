main.standard section.section.contact-info{
    .contact-info-cont{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div{
            font-size: 1.3rem;
            padding:.4em;
            width:300px;
            text-align: center;
        }
    }
    padding-top:20px;
    padding-bottom: 30px;
}