@import "@/style/mixins.scss";
@import "@/style/variables.scss";

header.main-header.help-page{
    display: grid;
    width:100%;
    grid-template-columns: 1fr 4fr;
    color:white;
}

main.help-page-main{
    @include horizontal-center-page;
    max-width: 1200px;
    padding:clamp(20px,3vw,5%);
    background:$off-white
}