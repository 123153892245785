textarea.full-flex{
    box-sizing: border-box;
    padding:0;
    border-radius: 0;
    overflow:hidden;
    margin-bottom: 0;
    font-size: inherit;
    // text-transform: inherit;
    :focus-visible{
        outline: unset;
    }
}