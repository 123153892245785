.hover-editor{
    position: relative;
    .modal-overlay{
        position: absolute;
        background: transparent;
        width: 0;
        .modal{
            transform: translateY(-100%);
            .close-modal-button{
                display: none;
            }
            button{
                margin-right: 1rem;
            }
        }
    }
}