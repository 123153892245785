@import "@/style/variables.scss";
@import "@/style/mixins.scss";

main.page-not-on{
    @include full-screen;
    background: $dark-blue;
    .content{
        @include absolute-center;
        @include flex-down;
        font-size: 1.4rem;
        color:$off-white;
        >div{
            margin-bottom: 40px;
            @include flex-down;
            input{
                margin-top: 20px;
                font-size: 3rem;
                width: 2em;
                text-align: center;
                padding:.2em;
            }
        }
        button{
            background: $off-white;
        }
    }

}