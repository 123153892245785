.back-button-cont{
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    button{
        display: flex;
        align-items: center;
        svg{
            margin-right: .5rem;
        }
    }
}