$overlay: rgb(0, 0, 0, 65%);

$dark-blue: #131759;
$off-white: rgb(253, 253, 253);
$offer-white:rgb(250,250,250);
$black: #050505;

// complimentary colors

$mid-blue: #0F4C7A;
$base-color: #131759;
$dark-red: #591317;

$dark-blue-shade: #0B2847;
$medium-blue: #1C2D78;
$light-blue: #3E4588;

$dark-green: #175913;

$dark-orange-brown: #75311B;

$split-complementary-color: #31751B;

$tetradic-dark-red: #591317;
$tetradic-dark-green: #175913;
$tetradic-dark-orange-brown: #75311B;




$mid-width:clamp(300px,80vw,600px)