@import "@/style/variables";

.elements-editor{
    position: relative;
    max-height: 500px;
    overflow-y: scroll;
    font-size: .8rem;
    .little-circle-button{
        background: $light-blue;
        top:10px !important;
        left:unset;
        right:10px !important;
        position: absolute;
    }
    br{
        display: block;
        height: 0px;
    }
    .html-edit-cont{
        display: flex;
        flex-direction: column;
        height: 100%;
        textarea{
            height: unset !important;
            flex-grow: 1;
            margin-bottom: 0;
        }
    }
}