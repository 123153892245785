section.section.subscribe{
    .services-cont{
        max-width: 300px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 2rem;
        row-gap: 2rem;
        margin-bottom: 3rem;
        &.short{
            display: flex;
        }
        @media screen and (max-width:800px) {
            margin-top: 50px;
        }
        img{
            width:80px;
            height: 80px;
            cursor: pointer;
        }
        &.editing{
            max-width: 800px;
            width:80vw;
            grid-template-columns: 1fr;
            .input-cont{
                display: flex;
                justify-content: center;
                align-items: center;
                input{
                    background:rgba(128, 128, 128, 0.164);
                    margin-bottom: unset;
                    margin-left:2rem;
                    width:80vw;
                    max-width:600px;
                    color:inherit;
                }
            }
        }
    }

}