@import "@/style/mixins.scss";

.form-editor{
    position: relative;
    .edit-button-generic{
        z-index: 1000;
    }
    header{
        padding:40px;
        display: flex;
        flex-direction: column;
        align-items: start;
        h1{
            input{
                font-size: 1.7rem;
            }
        }
        textarea{
            width:800px !important;

        }
    }
    .field-box{
        background:rgb(237, 237, 237);
        padding:30px;
        margin:50px;
        border-radius: 20px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        >div{
            
        }

    }
    .buttons-cont{
        @include flex-down;
        button{
            margin-bottom: 2em;
        }
    }
}