main.full-page-middle-scroller{
    position: fixed;
    background: rgba(128, 128, 128, 0.802);
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: 4000;
    display: flex;
    justify-content: center;
    overflow: scroll;
    >section{
        overflow: scroll;
        position: relative;
        padding:3rem;
        max-width: 1000px;
        background: white;
        min-height: 100vh;
        width: 1000px;
        padding-top: 0;
    }
}