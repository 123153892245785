.shuffle-circle-cont{
    display: flex;
    margin-top:20px;
    .shuffle-circle{
        background: rgb(20, 20, 20);
        height: 15px;
        width:15px;
        margin:25px;
        border-radius: 50%;
        opacity: .5;
        cursor: pointer;
        &.selected{
            opacity: .7;
        }
    }
        
}