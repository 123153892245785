@import "../../style/layout_classes.scss";

main.first-page{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    background:#f5f5f5;
    color:rgb(53, 53, 57);
    *{
        background:inherit;
    }
    h1{
        margin-top:0em;
        margin-bottom: 3rem;
        font-size: 3rem;
        font-size: clamp(2rem,3.5vw,4rem);
    }
    h3{
        text-align: center;
        margin-bottom: 2em;
        color:grey;
    }
    h2{
        margin-bottom: 1em;
    }
    select{
        font-size: clamp(1rem,2vw,1.8rem);
    }
    ul{
        font-size: 1.3rem;
    }
    .input-cont{
        margin-top:50px;
        font-size: clamp(1.5rem,2.6vw,3rem);
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 2em;
        input{
            font-size: clamp(1.5rem,2.6vw,3rem);
            margin:auto;
            margin-left:.5rem;
            border-bottom: 4px solid black;
            border-radius: 0;
            outline: none;
            box-shadow: none;
            font-size: 2rem;
            padding:0;
        }
    }
    .button-cont{
        height: 80px;
    }
    button{
        background:#111111;
        color:#eeeeee;
        margin-top:1rem;
        font-size: 1.7rem;
    }

}