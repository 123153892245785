.choose-subscription{
    margin-top:2rem;
    h3{
        margin-bottom:1em;
    }
    .error-message{
        color:crimson;
        font-weight: 600;
        margin-top: 1em;
        font-size: 1.4rem;

    }
    .button-cont{
        button{
            margin-right: 10px;
        }
    }
}

@media screen and (max-width:500px) {
    .page-status .subscription-box-cont{
        margin:1rem;
        margin-top:0px;
    }
}