@import "@/style/variables.scss";
@import "@/style/mixins.scss";


div.full-page{
    min-height: 100vh;
    @include flex-down;
    header.my-section-requests-header{
        justify-content: flex-start;
        h1{
            margin-left: 50px;
            color: $offer-white;
        }
    }
    main.my-section-requests-main{
        background: $offer-white;
        flex-grow: 1;
        width: 100%;
        @include flex-down;
        justify-content: flex-start;
        >button{
            margin: 1rem;
        }
        >div{
            div.section-request-card{
                margin: 1.2rem auto;

            }
        }
    }
}