@import "@/style/mixins.scss";
@import "@/style/variables.scss";


header.epk-header{
    @include flex-down;
    position: unset;
    background: $black;
    color:$off-white;
    h1,.h1{
        color:rgb(249, 84, 84);
        font-size:2.5rem;
        margin-top:1.5em;
    }
    img{
        margin-top: 30px;
        width:clamp(300px,50vw,600px)
    }
    padding-bottom: 20px;
}

main.epk-main{
    background:$black;
    color:$off-white;
    section.bio-section{
        padding:30px;
        p{
        margin: auto;
            max-width: 90vw;
            width:900px;
        padding:30px;
        background: rgb(255, 120, 120);
        color:rgb(79, 48, 48);
        }
    }
    section.epk-buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 120px;
        button{
            border-radius: 0;
            width: 150px;
            margin: 20px;
            text-transform: uppercase;
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    section.epk-socials{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 120px;
        div{
            scale:.8;
        }
    }
}