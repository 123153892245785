.delete-circle{
    background: grey;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    border-radius: 50%;
    color: black !important;
    z-index: 100;
    &.within{
        bottom: 0px;
        top: unset;
        right: 40px;
    }
    &.top-right{
        top:30px;
        right:40px;
    }
}

.delete-circle:hover{
    background: #796161;
}