.overlay.confirmation-overlay{
    background: #00000044;
    width:100vw;
    opacity: 1;
    
    .modal{
        padding-top:1.5rem;
        top:50%;
        transform: translate(-50%,-150%);
        .button-cont{
            margin-top:1rem;
            button{
                margin-right:1rem;
            }
        }
    }
}