.spotlight-card{

    display: flex;
    position: relative;
    width: fit-content;
    display: grid;
    padding:30px;
    margin:30px;
    background: rgb(236, 244, 244);
    box-shadow: 10px 10px #2f5476;
    cursor: pointer;
    img{
        width: 300px;
        max-width: 90vw;
        border:5px solid black;
        @media screen and (max-width:500px) {
            width:250px;
        }
    }
    footer{
        margin-top: 20px;
        align-self: self-end;
        justify-self: self-end;
    }
    &:nth-child(odd){
        transform: translateY(-50%);
    }

}