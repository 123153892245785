div.section-template-card{
    min-height: 100px;
    background: rgb(248, 248, 248) ;
    width:calc(100vw - 20px);
    margin:20px auto;
    display: grid;
    grid-template-columns: unset;
    box-sizing: border-box;
    >header{
        width:calc(100vw - 20px);
        padding:20px;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        background: white;
        .section-type{
            justify-self: center;
        }
        box-shadow: 2px 2px 4px #8d8989;
    }
    .stretch-dragger{
        display: none;
    }
    .preview-cont{
        height: unset;
    width:calc(100vw - 20px);
        padding:0;
        height: fit-content !important;
        margin-bottom: 0;
        .stretch-frame{
            border:none;
        }
        .stretcher{
            overflow-y: scroll;
                width:calc(100vw - 20px);

        }
        .section-preview{
            h1{
                text-align: unset;
            }
        }
    }
    footer{
        padding:20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}