header{
    position: sticky;
    top: 0;
    background: var(--header-bg);
    opacity: 1;
    z-index: 100;
    width:100%;
    box-sizing: border-box;
}



header select{
    margin-bottom: 0;
    margin-left:20px;
}

header input:checked + .slider {
    background-color: #2997326b
}