@import "../../style/variables";


.color-selector{
    position: relative;
    z-index:2000;
    margin: auto;
    margin-bottom:1rem;
    padding:0rem;
    .color-selector-body{
        display: grid;
        grid-template-columns: 1fr 3fr;
        .sketch-picker,.circle-picker,.github-picker,.bottom-opt{
            grid-column: 1/3;
        }
    }
    button{
        text-shadow: 2px 2px solid white;
    }
    // .color-btn{
    //     margin-left:1rem;
    // }
    span{
        padding:.6em;
        color:rgb(1, 1, 1);
        background: rgba(243, 242, 242, 0.574);
    }
    .color-box{
        height: 1rem;
        width: 1rem;
    }
    .color-picker-modal-overlay{
        background: none;
        .color-picker-modal{
            top: 0;
            height: 90vh;
            overflow-y: scroll;
            position: fixed;
            box-sizing: border-box;
            padding-top: 0;
            left:0;
            transform: unset;
            padding:0;
            .modal-content{
                max-height: unset;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                >div{
                    padding-left:0;
                    >*{
                        margin-top: .3em;
                        margin-bottom: .3em;
                    }
                }
                .picker-cont{
                    .input-cont{
                        margin-bottom: 1rem;
                        color:blue;

                    }
                }

            }
            .close-modal-button {
                display: inline;
                width: 20px;
                margin:10px 20px;
            }
        }
    }
}

.layout-modal select{
    background: #e8e4e4;
    margin-bottom:1rem;
}

.color-selector button{
    margin-top: .7em;
}

.color-selector button>div{
    margin:auto !important;
}

.color-selector{
    >div>button{
        // float:right;
        width:80px;
        height: fit-content;
        margin:unset;
    }
    select{
        width:fit-content !important;
    }
    .bottom-opt{
        display: flex;
        padding-bottom: 2rem;
        height: fit-content;
        justify-content: center;
        .slider-cont{
            padding:1rem 0;
            height: 60px;
            .custom-slider{
                margin-top: 1rem;
                margin-bottom:1rem;
                box-shadow: unset;
                width:200px;
            }
        }
        .hex-cont{
            padding:10px;
            margin-top:1rem;
            display: flex;
            flex-direction: column;
            height: 60px;
            width:100px;
            label{
                margin-bottom: .5em;
            }
            input{
                width:100px;
            }
        }
    }
}
    
.circle-picker{
    width: 90% !important;
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto !important;
    background: #fafafaff;
    align-items: center;
    padding-bottom: 8px;
    span{
        line-height: unset;
    }
    >span>div{
        // margin-bottom: unset !important;
        margin-right: unset !important;
        margin-bottom: 8px !important;
        margin:8px;
    }
}

.github-picker{
    width:350px !important;
}