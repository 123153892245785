.loading-overlay{
    position:fixed;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    background:rgb(250, 250, 250);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-overlay>h1>span{
    font-size:4rem;
    display: inline-block;
    animation: bounce 2s ease-in-out infinite;
}

@keyframes bounce {
    0% {
      transform: translateY(0);
      color:rgb(63, 63, 63);
      
    }
    25% {
        transform: translateY(-10px);
        color:rgb(79, 79, 79);
      }
    50% {
      transform: translateY(0px);
      color:rgb(63, 63, 63);
    }
    100% {
      transform: translateY(0);
      color:rgb(63, 63, 63);
    }
  }
  
