.mobile-dynamic-nav-header
.hamburger-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    opacity: 1;
    box-shadow: none;
    transform: unset;
    z-index: 10;
    padding:10px;
    background:none;
}
  .hamburger-line {
    display: block;
    width: 30px;
    height: 3px;
    background: #d7d7d7;
    margin: 5px 0;
    transition: all 0.7s ease-in-out;
  }
  
  .hamburger-button.open .hamburger-line:nth-child(1) {
    transform: rotate(45deg) translate(7px, 6px);
  }
  
  .hamburger-button.open .hamburger-line:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger-button.open .hamburger-line:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }
  
