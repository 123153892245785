@import "@/style/mixins.scss";

.layout-modal{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    min-width: unset;
    .move-bar{
        background:rgb(79, 79, 162);
        height: 50px;
        cursor: grab;
        display: flex;
        justify-content: center;
        align-items: center;
        position:relative;
        .close-modal-button{
          left:unset !important;
        }
        &.grabbing{
          cursor: grabbing;
        }
        h3{
          font-size: 1.3rem;
          color:rgb(230, 220, 220);
        }
      }
    &.custom-layout-modal{
        transform: translate(-50%,-50%);
        position: fixed;
        border-top: 7px solid #7f7f7f;
        @include sleek;
    }
    h3{
        font-size: .9rem;
    }
    .close-modal-button{
        padding: 0 10px;
        z-index: 1000;
        left:unset;
        color:black;
    }
    color: rgb(60, 60, 60);
    top: 150px;
    padding: 0;
    border-radius: 3px;
    border-top: 10px solid midnightblue;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 9000;
    .modal-content{
        font-size: 1rem;
        max-height: 50vh;
        overflow-y: scroll;
        width:100%;
        padding:0;
        padding-left: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        .color-selector-cont{
            padding:1rem;
            .slider-cont{
                display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
                .custom-slider{
                    width: 100%;
                    margin-top: 20px;
                }
            }
        }
        .properties-list{
            width: 100%;
            padding:20px;
            input{
                width: 80px;
                padding: 0.3em;
                text-align: right;
                margin-right: 1em;
            }
            .color-selector,.option-btn-div{
                width: 100%;
            }
            button,select{
                width:100% !important;
            }
            .number-and-unit{
                display: flex;
                align-items: center;
                margin: 0.5em 0;
                input{
                    flex-grow: 1;
                    margin-bottom: 0;
                }
            }
            label{
                margin-bottom: .5em;
            }
        }
        >div{
            width: fit-content;
        }
        >div>div{
            width: 100%;
        }
        .option-btn-div{
            width: fit-content;
            padding-top:.4rem;
        }
        .color-selector{
            margin:unset;
            margin-bottom: .5rem;

            button{
                width:unset;
            }
            button{
                margin-top: 0;
            }
        }
        select{
            display: block;
            width:150px;
            font-size: .9rem;
            padding:0.2rem;
            margin-left: 0;
        }
        input[type="range"]{
            display: block;
            box-shadow: unset;
            width:unset
        }
    }
    .color-picker-modal{
        transform: unset;
    }
}

.open-layout-btn{
    z-index: 200;
    color:white;
    background: #1b84d7;
    border-radius: 1.5rem;
    padding:6px 10px;
    @media screen and (max-width:600px) {
            display: none;
    }
}

