section.section.linked-image-grid{
    --imageHeight:30vw;
}


div.linked-image-grid{
    display: flex;
    grid-auto-rows: auto;
    grid-gap: 5px;
    max-width:90%;
    flex-wrap:wrap;
    justify-content:center;
    min-height:10vw;
    img{
        width:100vw;
    }


    @media screen and (min-width:"800px") { 
        >div{
            flex-grow:1;
            display:flex;
            position:relative;
            width:fit-content;
            justify-content: center;
            img{
                width:unset; 
                height: var(--imageHeight);
                object-fit: cover;
                object-position: center;
                flex-grow: 1;
            }
    }
    padding-bottom: 2rem;
}
}
button.image-add-button{
    margin:2em;
}