.toggle-on-cont{

    z-index: 900;
    div{
        display: flex;
        justify-content: center;
        align-items: center;
        .label-span{
            margin-right: .5em;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
        }
    }
}