@import "@/style/variables";
@import "@/style/mixins";

header.purchase-tokens-header{
    position: sticky !important;
    background: $dark-blue;
}
main.tokens-main{
    width: 100%;
    @include flex-down;
    h2{
        margin: 1em auto;
        text-align: center;
    }
    div.purchase-area{
        @include flex-down
    }
    .back-to-dash-btn{
        background: red;
        margin-top: 20px;
        color: white;
    }
    button{
        width:240px;
    }
}