@mixin mobile {
    @media (max-width: 720px) {
      @content;
    }
}

@mixin what-you-get {
      background: inherit;
      color:inherit;
      margin:unset;
      font-family: inherit;
      font-size: inherit;
      text-transform: inherit;
      padding:0;
      box-shadow: none;
}

@mixin center-modal {
  position: fixed;
  top:50%;
  transform: translate(-50%);
}

@mixin standard-modal{
  font-family: sans-serif;
    color:black;
    .modal-content{
      display: flex;
      flex-direction: column;
    }

}

@mixin save-modal{
  .close-modal-button{
    display: none;
  }
  button{
    background:green;
    color:white;
  }
}

@mixin standard-input-cont{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@mixin tooltip-down{
    &.tooltip::before{
        top:unset;
        bottom:0;
        transform: translate(-50%,100%);
    }
    &.tooltip::after{
        top:unset;
        bottom:0;
        transform: translate(-50%,50%) rotate(45deg);
    }
}

@mixin tooltip-down-right{
  &.tooltip::before{
      top:unset;
      bottom:0;
      transform: translate(0%,100%);
  }
  &.tooltip::after{
      top:unset;
      bottom:0;
      transform: translate(0%,50%) rotate(45deg);
  }
}

@mixin quillImages{
      img{
      &.left{
        float: left;
        width:50%;
        @media screen and (max-width:500px) {
          width:100%
        }
      }
      &.right{
        float: right;
        width:50%;
        @media screen and (max-width:500px) {
          width:100%
        }
      }
      &.full{
        width:100%
      }
    }
}

@mixin blue-button{
  z-index: 200;
  color: white;
  background: #1b84d7;
  border-radius: 1.5rem;
  padding: 6px 10px;
}

@mixin full-page{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  main{
      box-sizing: border-box;
      background:#f8f8f8;
      flex-grow: 1;
  }
}

@mixin pulse{
  animation: pulse 1s infinite ;
}

@keyframes pulse {
  0%{
    scale: 1;
  }
  50%{
    scale:1.08;
  }
}

@mixin absolute-center{
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

@mixin horizontal-center-page{
  max-width: 800px;
  padding:20px;
  margin:auto;
}

@mixin flex-down{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


@mixin full-screen{
  width: 100vw;
  height: 100vh;
}

@mixin collapse-2-to-1{
  @media screen and (max-width:1000px) {
      >*{
        grid-column: 1/3;
      }
  }
}

@mixin resets{
  form{
    display: unset;
    margin-top: unset;
    >div{
      width: unset;
    }

  }
  input,textarea{
    width:unset;
    border-bottom: none;
  }
  .video-cont{
    // padding-bottom: 0;
    // height: 100%;
    width:unset;
  }
  .centerer{
    width: unset;
    max-width: unset;
    min-width: none;

  }
  .auto-textarea{
    width:100%;
  }
  .e-button-cont{
    width:fit-content;
  }
  .e-button{
    input{
      text-align: center;
    }
  }
  .auto-textarea-cont{
    textarea.auto-textarea{
      width: 100%;
    }
  }
  .h1,.h2{
    margin-block-start:0;
    margin-block-end:0;
  }
}

@mixin sleek{
    background: #fefefeff;
    border-radius: 3px;
    font-weight: 400;
    .move-bar{
      background: #f5f5f5;
      h3{
        font-size: 1.1rem;
        color:#555555;
        font-weight: 500;
      }
    }
    label{
      margin-bottom: .5em;
    }
}

