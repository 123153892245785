main.standard section.section.mailing-list{
    padding-top:4rem;
    padding-bottom:4rem;
    @media screen and (max-width:800px) {
        padding-top:1rem;
        padding-bottom: 2rem;
    }
}

.instructions{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    form{
        margin-top: 40px;
        label{
            display: block;
            width:100px;
            margin-bottom:.7em;
        }
        input{
            width:500px;
            max-width: 80vw;
        }
    }
}

.mailing-list-cta-cont{
    margin:auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width:clamp(250px,80vw,900px);
    padding:1rem;
    @media screen and (max-width:800px) {
        flex-direction: column;
        text-align: center;
        h3{
            font-size: clamp(1.3rem,3vw,2rem) !important;
            text-align: center;
        }
    }
    h3{
        display: inline;
        width:unset;
        font-size: 3rem;
        text-align: right;
        padding:1em;
        @media screen and (max-width:800px) {
            text-align: center;
        }
    }
    .input-and-button-cont{
        // background:black;
        display: flex;
        padding:1rem;
        align-items: center;
        button{
            height: 3rem;
            background:white;
            color:black;
            border-radius: 0 5px 5px 0;
            transition: all 300ms;
            
        }
        button:disabled{
            opacity: .2;
            width:0px;
            padding:0;
            overflow: hidden;

        }
        input{
            height: 3rem;
            border:3px solid black;
            box-sizing: border-box;
            margin:unset;
            border-radius: 0px;
        }
    }
}