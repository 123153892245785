main.standard section.section.three-items{
    .content{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    align-items: start;
    max-width: 80vw;
    .subsection{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:clamp(3rem,2vw,5rem);
        max-width: 400px;
        .heading_class{
            font-size: 2rem;
            margin-bottom:.5em;
            text-transform: uppercase;
            font-family: "Oswald";
            text-align: center;
        }
        img{
            margin-bottom: 1rem;
            width:230px;
        }
        .paragraph_class{
            font-size: 1.2rem;
            line-height: 1.4em;
        }
    }
}
}