.auto-textarea{
    text-align: inherit;
}

.e-textarea{
    font-size: 16px;
}

section.custom-template{
    textarea.auto-textarea{
        width: 100%;
        text-transform: inherit;
        font-family: inherit;
    }
    .edit-img-cont{
        max-width: unset;
    }
}