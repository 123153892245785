.footer-style>section{
    background: rgb(224, 222, 225);
}

.footer-grid{
    width: 90vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr repeat(2, 2fr) 1fr;
    justify-items: start;
}

.footer-style .logo-cont{
    color:white
}

.footer-style .logo{
    height:60px
}

.footer-style .logo-cont{
    grid-row-start: 2;
}

.footer-style .socials{
    grid-column-start: 1;
    grid-row-start: 3;
    padding-left: 50px;

}

.footer-style .social-icon{
    color: rgb(223, 222, 222);
    width: 30px;
    padding: 0.5rem;
}

.footer-style .social-icon:hover{
    color: white;
}

.footer-style .copyright{
    grid-row-start: 4;
    grid-column-start: 1;
    grid-column-end: 3;
}

.footer-links{
    color:white
}

.footer-links ul{
    margin: 0;
    padding: 0;
    text-align: left;
}

.footer-links li{
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top:.5em
}

.footer-links a{
    color:white;
    text-decoration: none;
    font-size: 1.2rem;

}

.footer-links-0{
    grid-column-start: 3;
    grid-row-start: 2;
    grid-row-end:4 
}
.footer-links-1{
    grid-column-start: 4;
    grid-row-start: 2;
    grid-row-end:4 
}

@media only screen and (max-width: 600px) {
    .footer-grid{
        width: 90vw;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: unset;
        justify-items: start;
    }


    .footer-style .logo-cont {
        grid-row: 1;
        grid-column-start: 1;
        grid-column-end: 3;
        width: 100%;
        justify-content:center;
    }

    .footer-links ul{
        text-align: center !important;
    }
    .authentication-modal input{
        width: unset;
    }

    .footer-style .socials{
        grid-row-start: 2;
    }

    .footer-links-0{
        grid-column-start: 1;
        grid-row-start: 2;
        grid-row-end:4 
    }
    .footer-links-1{
        grid-column-start: 2;
        grid-row-start: 3;
        grid-row-end:4 
    }

    .footer-style .copyright{
        padding-top:20px;
        grid-row-start: 5;
        grid-column-start: 1;
        grid-column-end: 5;
    }
}