.section-submission-preview{
    border:3px grey dashed;
    flex-grow: 1;
    header{
        nav{
            h3{
               
                display: none;
            }
        }
    }
}