.count-floater{
    background: red;
    color:white;
    position: absolute;
    top:0;
    right:0;
    transform: translate(-50%);
    width:20px;
    height: 20px;
    opacity: 1;
    z-index: 100;
    border-radius: 50%;
    span{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
    }
}