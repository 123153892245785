@import "@/style/mixins.scss";
@import "@/style/variables.scss";
header.developers-splash-header{
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    button{
        width: 200px;
        font-size: .9rem;
        border-radius: 0;
        background:none;
        border: 2px solid #222222;
        }
}

main.developers-splash{
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include collapse-2-to-1;
    >section{
        min-height: 400px;
        @include flex-down;

    }
    section.right{
        background-color: lighten($mid-blue,10%);
        color:lighten($dark-red,75%);
        padding: 2em;
        h2{
            margin-bottom: 2em;
            line-height: 1.7em;
        }
    }
    section.left{
        background: $dark-blue;
    }
    section.full-width{
        grid-column: 1/3;
    }
    .work-for-you{
        background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Adult-coding-collaborate-1181472.jpg/800px-Adult-coding-collaborate-1181472.jpg?20190429061413);
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        .overlay{
            background:linear-gradient(to right, rgb(115, 185, 250) , rgb(34, 33, 33));
            opacity: .6;
            z-index: 1;
        }
        .left{
            @media screen and (max-width:700px) {
                display: none;
            }
            width: 50%;
            z-index: 2;
            height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
            h1{
                font-size: clamp(2rem, 2vw, 2rem);
                color: #fff493;
                padding: 0.3em;
                margin: 50px;
                text-align: center;
            
            }
        }
        .right{
            z-index: 2;
            h3{
                margin:1em;
                margin-left: 0;
                color: lighten($dark-red,90%);
                font-size: 2rem;
            }
            ol{
                color:rgb(26, 48, 121);
                background:#ffffff61;
                padding:1rem;
                li{
                    font-weight: 700;
                    margin-left: 1em;
                    font-size: clamp(1rem,2vw,1.7rem);
                }
            }
        }
    }
    .underline{
        text-decoration: underline;
    }
    section.passive-income{
        min-height: unset;
        background:$offer-white;
        padding:10px;
        p{
            max-width: 700px;
            font-size: 1.2rem;
            margin-bottom: 40px;
        }
        h2{
            margin:2.5em;
            margin-bottom: 1em;
            text-align: center;
        }
    }
}

footer.developers-splash-footer{
    background:$dark-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:30px;
    button{
        font-size: clamp(1.2rem,2vw,1.6rem);
        padding:.5em 3em;
        border-radius: 2em;
    }
}