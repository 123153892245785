.edit-buttons-cont{
    display: flex;
    .tooltip{
        &::before{
            padding: 3px;
            width: 130px;
            color: #444444;
            background: #f7f7f7;
            border: 1px solid black;
            z-index: 2;
        }
    }
}