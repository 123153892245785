.three-grid{
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start !important;
    padding-top: 2rem !important;
    justify-items: center;
}

.three-grid div{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.three-grid>div>.img{
    width: 250px;
    height: 250px;
}

.three-grid>div>.h1{
    font-size: 2.5rem;
    text-align: center;
}

.three-grid>div>.p{
    font-size: 1.2rem;
    width:300px;
    background:transparent;
    font-family: inherit;
}

.three-grid .edit-img-cont .modal-overlay{
    width: 100vw;
}

.three-grid img{
    width: 300px;
    height: 300px;
}