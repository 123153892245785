.splash section.section.samples{
    background-color: rgb(227, 240, 227);
    padding-top:5rem;
    padding-bottom:5rem;
    overflow-y: clip;
}

.splash section.section.samples .grid{
    background: none;
    grid-template-columns: 2fr 3fr;
    width: 60vw;
}

.splash section.section.samples{
    font-size: 2rem;
}

.splash section.section.samples .inspiration-btn{
    font-size: 1.2rem;
    background: white;
    text-transform: uppercase;

}

.splash section.section.samples .vertical-text{
    display: flex;
    flex-direction: column;
    line-height:.9em;
    width:fit-content;
    font-size: 10rem;
}

.splash section.section.samples  .sample-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.splash section.section.samples h3{
    font-weight: 500;
    color:rgb(127, 83, 83);
}

.splash section.section.samples .sample-img-cont{
    position: relative;
}

.splash section.section.samples img{
    width:15vw;
    border-radius: 50%;
    border:10px solid rgba(85, 84, 84, 0.317);
    margin-bottom: 40px;
}

.sample-img-cont::before{
    height: 300px;
    width: 300px;
    background: rgba(0, 0, 0, 0.214);
    content: "";
    position: absolute;
    z-index: -1;
    rotate:0deg;
    animation: rotate-cont 20s linear;
    animation-iteration-count: infinite;
    box-shadow: 0px 0px 5px 10px rgba(0, 0, 0, 0.026);
    scale: 1.1;
    border-radius: 5px;
    opacity: .4;
    /* position: absolute;
    top: 0%;
    left:0%;
    transform: translate(-50%,-50%); */
}

.sample-img-cont::after{
    height: 300px;
    width: 300px;
    background: rgba(217, 86, 86, 0.259);
    content: "";
    position: absolute;
    z-index: -1;
    rotate:0deg;
    animation: rotate-cont 18s linear;
    animation-iteration-count: infinite;
    box-shadow: 0px 0px 5px 10px rgba(0, 0, 0, 0.026);
    scale: 1.1;
    border-radius: 5px;
    opacity: .4;
    transform: translate(-50%,-50%);
}

.splash .footer-style section.section{
    padding-bottom: 30px;
}

@keyframes rotate-cont {
    0% {
        rotate: 0deg;
        scale: 1.1;
        opacity: .4;
    }
    25%{
        translate:100px;
    }
    50% {
        rotate: 180deg;
        scale:1.2;
        opacity: .2;
        background: rgba(126, 135, 96, 0.214);
    }
    75%{
        background: rgba(143, 158, 95, 0.448);
        translate:-100px;
    }
    100% {
        rotate: 360deg;
        scale: 1.1;
        opacity: .4;
        /* background: initial; */
    }
}

@media screen and (max-width:800px) {
    .splash section.section.samples .grid{
        grid-template-columns: 1fr;
        width: 100vw;
    }
    .splash section.section.samples .grid h2{
        font-size: 1.8rem;
        margin:0 2rem 2rem 2rem;
        width: unset;
        display: inline;
    }
    .splash section.section.samples img{
        width:250px;
        border-radius: 50%;
        border:10px solid rgba(85, 84, 84, 0.317);
        margin-bottom: 40px;
    }
    .splash section.section.samples h3{
        margin-top: 0;
    }
    .splash section.section.samples{
        padding-bottom: 20px;
    }
    .sample-img-cont::before,.sample-img-cont::after{
        display: none;
    }
    
}