.logo-cont{
    display: flex;
    align-items: center;
}

.logo{
    margin:10px
}

.logo-cont h2{
    font-size: 2.5rem;
    margin:0;
    transform:translateY(5px);
    font-family:Geneva, Tahoma, sans-serif;
    letter-spacing: 0;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-line: overline;

}