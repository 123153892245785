.add-section-cont{
    z-index: 10000;
    .add-section-dropdown{
        font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        position:absolute;
        left:0;
        right:0;
        background:rgb(232, 230, 230);
        padding:1rem;
        padding-top:0;
        color:black;
        display: flex;
        flex-direction: column;
        box-shadow: 2px 2px 15px black;
        height: 100vh;
        align-items: center;
        overflow-y: scroll;
        max-height: 100vh;
        header{
            background: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            width:fit-content;
            input{
                margin:0;
                margin-bottom: 1rem;
            }
        }
        h2{
            margin:1em;
        }
        ul{
            text-transform: lowercase;
            display: grid;
            grid-template-rows: repeat(9,1fr);
            grid-auto-flow: column;
            margin-left: 1.5rem;
            width:fit-content;
            grid-column-gap:5rem;
            li{
                cursor: pointer;
                font-size: 1.4rem;
                padding:1em;
                list-style: none;
                white-space: nowrap;
                padding-right: 10px;
                padding:.4em 0;
            }
        }
        button{
            margin-left: 1rem;
            margin-top: 2em;
        }
    }
}