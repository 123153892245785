.side-buttons-cont{
    position: absolute;
    right:-3.5rem;
    top:0;
    transform:translateX(100%);
    display: flex;
    flex-direction: row;
    .svg-cont{
        cursor: pointer;
    }
    .edit{
        color:darkblue
    }
    .delete{
        color:darkred
    }
}