.explanation-btn{
    z-index: 200;
    color:white;
    background: #1b84d7;
    border-radius: 1.5rem;
    padding:6px 10px;
    text-align: center;
    width:55px;
    @media screen and (max-width:600px) {
            display: none;
    }
}

.layout-modal .modal-content{
    div.explanation-text{
        width:300px;
        font-weight: normal;
        line-height: 1.3rem;
    }
}