.full-page-middle-scroller section{
    display: flex;
    flex-direction: column;
    overflow: unset !important;
    min-height: unset !important;
    width: unset !important;
    .custom-text-editor{
        flex-grow: 1;
        height: unset;
        .live-content{
            height: 65vh;
        }
        select{
            border:1px grey solid;
        }
    }
}