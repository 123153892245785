.add-input-cont{
    display: flex;
    align-items: flex-end;
    position: relative;
    .input-cont{
        flex-direction: column;
    }
    button{
        background: blue;
        color:white;
        font-weight: 800;
        width:40px;
        height: 40px;
        display: flex;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        margin-bottom: 2.2rem;
        margin-left: 1rem;
    }
    .error-div{
        position: absolute;
        bottom:0;
        left:20%;
        transform: translate(-50%);
        
    }
}