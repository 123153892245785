main.standard section.section.agreement{
    .overlay{
        display: none;
    }
    img{
        width:150px;
        cursor: pointer;

    }
    .agreement-body{
        color:rgb(38, 38, 38);
        font-size: 1.3rem;
    }
    h1{
        font-size: 2rem;
    }
    p{
        font-size: 1.2rem;
    }
    padding-bottom: 40px;
    .quill-full-modal{
        left:0;
        right:0;
        top:0;
        bottom: 0;

        transform: unset;
        font-size: unset;
        .modal-content{
            max-width: unset;
            background: none;
            p{
                font-size: unset;
            }
        }
    }
}