.section-heading{
    font-weight: 400;
    position: relative;
    margin-bottom: 1em;
    margin-top: 1em;
    width:clamp(200px,70vw,500px);
    display: flex;
    justify-content: center;
    font-size: clamp(2rem, 3.5vw, 3.2rem);
    input{
        max-width: 80vw;
    }
}


// large
.section .section-heading.size-set-large{
    font-size: clamp(3rem, 5.5vw, 5.2rem);
} 

// small
.section .section-heading.size-set-small{
    font-size: clamp(1.5rem, 2.5vw, 2.3rem);
}
