.page-number-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    button {
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px 15px;
        background-color: white;
        margin-right: 10px;
        font-size: 14px;
        cursor: pointer;
        outline: none;
        :hover {
            background-color: #ccc;
            color: white;
          }
        &.active {
            background-color: #333;
            color: white;
          }
        &.current{
          background: rgb(184, 180, 180);
          color:black;
          opacity: .7;
          cursor: none;
        }
        @media screen and (max-width:800px) {
            padding:5px 10px;
        }
      }
      .gap{
        margin:auto 10px;
        background-color: grey;
        height: 2px;
        width: 20px;
        @media screen and (max-width:800px) {
          margin:auto 4px;
          width: 10px;
        }
      }
  }