@import "@/style/mixins.scss";

.section-chooser{
    padding:1.5rem;
    background-color: aliceblue;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // display: grid;
    header{
        padding: 0 2rem 2rem 0;
    background: none;
    display: unset;
    p:first-child{
        margin-bottom: 1em;
    }
    a{
            text-decoration: underline;
            font-weight: 700;
            cursor: pointer;
        }
    }
    .section-list{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: calc(100vh - 290px);
}

    .choice{
        cursor: pointer;
        margin-top: .5em;
        width: fit-content;
        font-size: clamp(.9rem,2vw,1.2rem);
        font-weight: 500;
    }
    footer{
        a{
            text-decoration: underline;
            font-weight: 700;
        }
    }
}