article.conversation-preview{
    background: white;
    padding:1rem;
    cursor: pointer;
    &.current{
        background:rgb(221, 221, 236);
    }
    h4{
        margin-bottom: .5em;
    }
}