main#login-page{
    min-height: 100vh;
    background: var(--neutral-bg);
}

main#login-page header{
    background:transparent !important;
}

.close-margin section{
    margin-top: 0rem !important;
}

main#login-page header img{
    margin:1rem 3rem;
    width: 100px;
}

#login-page header a{
    text-decoration: none;
}

main#login-page header h1{
    margin:2rem;
    cursor: pointer;
    text-decoration: none;
    color: black;
    
}

#login-page section{
    margin-top: 60px;
}

#login-page h1{
    font-size: 3rem;
    margin-bottom: 0;
}

#login-page h3{
    margin-bottom: 2em;

}

.side-by-side{
    margin-top:70px
}

.login h3{
    padding-left: 60px;
}

.login form>div{
    padding: 20px;
    padding-right: 50px;
}

.login form{
    padding-left: 60px;
}

.login{
    display: grid;
    grid-template-columns: 1fr 1fr;

}


.login form>div{
    margin: 1rem 0rem;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
}

.login form>div div{
    margin:0 !important;
}

.login form div label{
    color:gray;
}

.login form input{
    width: 350px;
    background-color: transparent;
    background: transparent;
    box-shadow: none !important;
    border-bottom: 2px solid grey;
    border-radius: 0;
    font-size: 1.2rem !important;
}

.login form button{
    --background-color:rgb(90, 90, 187) !important;
    background-color: var(--background-color)
}

.authentication-services button{
    --background-color:rgb(27, 27, 153) !important;
    width: 350px;
    border: var(--background-color) 3px solid !important;
    margin-top: 18px;
}

.authentication-services button:hover{
    --background-color:rgb(0, 0, 177) !important;
}

.authentication-services button div{
    background: var(--neutral-bg);
}

.authentication-services button span{
    background: var(--background-color) !important;
    margin:auto;
    color:black;
    font-weight: 600 !important;
    color: white;
    padding: 16px !important;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}


input::placeholder {
    font-size: 20px;
  }

  .confirmation-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(7, 7, 7, 0.7);
  }
  .confirmation-message{
    background: white;
    padding:300px;
    position: fixed;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    font-size: 1.2rem;
    padding: 2rem 1rem;
    max-width: 80vw;
    border: 3px solid rgb(139, 138, 138);
    box-shadow:12px 12px 2px 1px rgba(0, 0, 255, .2);
}

@media screen and (max-width:850px) {
    #login-page section{
        margin-top: 0;
        width: unset;
    }
    #login-page section h1{
        margin-top:.5rem
    }
    #login-page .login{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    #login-page .login form{
        margin-top:2rem;
        padding-left: 0;
    }

    .login form input{
        width:278px;
        margin-bottom:0;
    }
}
@media screen and (max-width:500px) {
    main#login-page header{
        position: relative;
        padding: 0;
    }
    main#login-page header a{
        position: absolute;
        right:20px;
        padding: .5em .7em;
    }
    main#login-page header a h1{
        font-size: 1.2rem;
        margin:0;
    }
    main#login-page header img{
        width: 60px;
        margin: 1rem 1rem
    }
    .side-by-side{
        margin-top: 0;
    }
    .authentication-services{
        padding:0
    }

    #login-page section h1{
        font-size: 2rem;
        margin-top: 0.5rem;
    }

    #login-page section h3{
        font-weight: 500;
        margin:1rem 0;
        padding-left: unset;
    }

    .login{
        display: flex;

    }

    button.add-page-btn{
        margin:.5em 0;
    }

    #login-page .login h3{
        margin-top:30px
    }

    .authentication-services{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0;
    }

    .authentication-services button{
        width: 310px;
    }

    .authentication-services p{
        text-align: center;
        margin-bottom: 0;
    }
}