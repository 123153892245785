section.section{
    overflow-x: clip;
    padding: 2rem;
    padding-top:0;
    min-height: 100px;
    /* background-color: var(--primary-section-bg); */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 0;
    width: 100%;
    /* max-width: 1400px; */
    margin: auto;
    box-sizing: border-box;
    background-size: cover;
    --overlayOpacity:0;
    .edit-section-header{
        display: none;
        &.style-open{
            display: flex;
        }
    }
    &:hover{
        .edit-section-header{
            display: flex;
        }  
    }

    &.first{
        min-height: 200px;
    }
}

section.section.alternating:nth-child(even){
    background-color: var(--alternating-section-bg);
}

section.section.modal-up{
    z-index: 1;
}

@media screen and (max-width:850px) {
    .standard section.section{
        padding: unset;
    }
    section.section.standard{
        padding: unset;
        padding-bottom: 50px;
        width:100% !important
    }
    main.grid>section::before{
        background: rgba(0, 0, 0, 0.037);
    text-shadow: 3px 3px 5px black;
    content: "" !important;
    position: unset !important;
    z-index: 200;
    font-size: 1rem;
    // height: 38px;
    }
    .toggle-on-cont {
    right:0px !important;
    }
}