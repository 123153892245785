.inactive-pages{
    padding:2rem 0;
    h2{
        margin:1em 0;
    }
    .page-line{
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
        span{
            font-size: 1.2rem;
            margin-right:2rem;
        }
        button{
            padding:.2rem .4rem;
        }
    }
}