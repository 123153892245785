.edit-social-div{
    display: flex;
    margin: 20px;
    justify-content: center;
    align-items: center;
    transform: translateX(-30px);
}

.edit-social-div:first-child{
    margin-top: 70px;
}

.edit-social-div input{
    margin-left: 25px;
    width: 350px;
    background: #544949;
    color: white;
    letter-spacing: .05em;
}

.edit-social-div .social-icon{
    padding: 0 !important;
}

.social-input-cont{
    display: flex;
    align-items: center;
}
.social-input-cont input{
    margin:20px !important;
}

section.socials{
    max-width: unset;
    .socials{
        margin-bottom:4rem;
    }
}

@media only screen and (max-width: 600px) {
    .edit-social-div{
        flex-direction: column;
        transform: unset;
    }
    .edit-social-div .social-icon {
        padding-bottom: 1rem !important;
    }
    .edit-social-div input{
        max-width: 80vw;
        margin-left: unset;
    }
}