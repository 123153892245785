section.themes-section{
    padding:4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
}

.themes-section .font-cont{
    width: 500px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
}