.domain-add-cont{
    padding:1rem 2rem;
    button{
        height: 2.8rem;
        padding:unset;

    }
    button{
        padding-right:1rem;
        padding-left: 1rem;
        margin-left: 1rem;
    }
    input{
        margin-bottom: .5rem;
    }

}

.current-domain-cont{
    padding:2rem;
    button{
        margin-left:2rem;
    }
}