@import "@/style/variables";

header.articles-header{
    background: $dark-blue;

}

main.articles-list-main{
    padding-top:120px;
    width:$mid-width;
    margin:auto;
}