.add-section-view{
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    background: rgb(251, 251, 251);
}

.add-section-right{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    .add-section-confirm-cont{
        padding:20px;
    }
}