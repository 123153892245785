.standard section.hero{
    .toggles-cont{
        >div
        {
            >span,.label-span{
                background:rgba(255, 255, 255, 0.337);
                
            }
        }
    }

    .text-cont{
        background: none;
        width:100%;
        // background-color:rgba(237, 120, 62, 0.7);
        background-color:var(--textContBackground);
        display: grid;
        margin-left: unset !important;
        left:unset !important;
        right:unset !important;
        transform: unset !important;
        padding-left: 8vw;
        textarea{
            box-shadow: unset;
        }
        .h1{
            line-height: 1em;
            margin:0;
            margin-bottom: .5em;
            }
        .h2{
            width:100%
        }
    }
    &.right__textHor{
        .text-cont{
            padding-right:8vw;
            text-align: right;
        }
    }
    &.center__textHor{
        .text-cont{
            padding-left: 8vw;
            padding-right:8vw;

            text-align: center;
        }
    }
    &.top__text .text-cont{
        top:0;
        padding-top:2vw;
        transform:unset
    }
    &.middle__text .text-cont{
        top:50%;
        bottom:unset;
        transform: translateY(-50%) !important;
        padding-top: 1vw;
        padding-bottom: 1vw;

    }
    
    &.bottom__text .text-cont{
        top:unset;
        bottom:0;
        transform: translateY(0%);
        padding-bottom: 2vw;
    }
}

.hero .image-cont{
    width:100vw;

}

section.hero.topHalf__verticalImageClip{
    .image-cont{
        height: 500px;
        overflow: hidden;
        position: relative;
        @media screen and (max-width:500px) {
            height: unset;
        }
        img{
            position: absolute;
            @media screen and (max-width:500px) {
                position: unset;
            }
        }
    }
    .edit-img-cont{
        position: unset;
        height: 100%;
        img{
            position: absolute;
            top:0;
        }
    }
}

section.hero.bottomHalf__verticalImageClip{
    .image-cont{
        height: 500px;
        overflow: hidden;
        position: relative;
        img{
            position: absolute;
            bottom:0;
        }
    }
    .edit-img-cont{
        position: unset;
        height: 100%;
        img{
            position: absolute;
            bottom:0;
        }
    }
}

.hero .edit-img-cont{
    width:100%;
    max-width: unset;
}

.hero .edit-img-cont img{
    max-width: unset;
    width:100%;
    min-height: 200px;
}

.hero{
    display: flex;
    max-height: 800px;
    align-items: flex-start !important;
    padding:0 !important;
    color: #ffffffb3;
    --overlayOpacity:0.1;
}

img.full-width{
    width: 100%;
    z-index: 0;
}

.cover{
    justify-self: center;
}

.drop-open .cover{
    margin:auto
}

.overlay{
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right:0;
    background: rgb(0, 0, 0);
    z-index: 1;
    opacity: var(--overlayOpacity);
}

.section.drop-open{
    display: flex !important;
    flex-direction: column-reverse;
    background: transparent !important;
    border:2px dashed black
}

.section.block__stack{
    display: flex;
    flex-direction: column-reverse;
}

.section.block__stack .text-cont, section.drop-open .text-cont{
    transform: translateY(0);
}

.section.block__stack .overlay,.section.drop-open .overlay{
    display: none;
}

.section.block__stack .text-cont,.section.drop-open .text-cont{
    position: unset;
}



.text-cont{
    position: absolute;
    z-index: 4;
    top:50%;
    transform: translateY(-50%);
}

.hero .text-cont  h1,.text-cont  .h1{
    font-size: 6vw;
    font-family: inherit;
}
.hero .text-cont  h2,.text-cont  .h2{
    margin-top: 0em;
    font-size: 3vw;
    font-weight: 300;
    font-family: inherit;
}

.hero .delete-circle{
    background: #ec0f0f;
    position: absolute;
    top: unset;
    right: 40px;
    bottom:20px;
    transform: unset;
    border-radius: 50%;
    color: white;
    border-radius: 5px;
    color:transparent !important
}

.hero button.delete-circle::before{
    /* position: absolute; */
    content: "Click Here to Replace Image";
    position: relative;
    top: unset;
    left: unset;
    transform: translateX(-50%);
    width: 100px;
    color:white;
    padding:none;
    opacity: 1;
    visibility: visible;
    background: unset;
}

.hero button.delete-circle::after{
    display: none;
}


.hero .image-cont{
    width: 50vw;
}

.hero.drop-open{
    align-items: flex-end !important;
    background-color: #3a3030;
}

.drop-open .cover{
    width: 50vw;
    z-index: 10;
}

.drop-open .image-cont{
    width: 50vw;
}

.drop-open .edit-img-cont{
    width: 50vw;
}

.hero.isOff{
    padding-left:2rem !important;
    padding-right:2rem !important
}