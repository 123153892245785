.hero{
    display: flex;
    max-height: 800px;
    align-items: flex-start !important;
    padding:0 !important;
}

img.full-width{
    width: 100%;
    z-index: 0;
}

.cover{
    justify-self: center;
}

.hero .cover{
    margin:auto;
    width:100%;
    min-height: 18vw;
}

.hero .cover .overlay{
    width: 100%;
    opacity: var(--overlayOpacity);
}

.overlay{
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right:0;
    background: rgb(0, 0, 0);
    z-index: 1;
    margin:auto;
}

.text-cont{
    position: absolute;
    z-index: 4;
    margin-left: 100px;
    top:50%;
    transform: translateY(-70%);
}

.text-cont  h1{
    font-size: clamp(2rem,7vw,6rem);
    margin-right:2rem;
}
.text-cont:not(".mobile-frame-text-cont")  h2{
    font-size: clamp(1.3rem,4vw,4rem) !important;
    margin-right:2rem;
}

.contact-form form input,.contact-form form textarea{
    width:400px;
    max-width: 80vw;
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    background: #ffffff29;
    border: 1px solid black;
    border-radius: 0;
    margin-top: 1rem;
}

.contact-form form{
    display: flex;
    flex-direction: column;
}

.recaptcha-cont{
    margin: auto;
}

.contact-form form button{
    margin: auto;
    margin-top: 1em;
    margin-bottom:3rem;
    padding: 1em 3em;
    color: #1d1b1b;
    background-color: rgb(240 240 243);
}

