
.expander header{
    background: none;
    border-top:1px solid rgb(195, 192, 192);
}

.expander header h3{
    cursor: pointer;
    font-weight: 500;
    letter-spacing: .04em;
}

.expander main{
    box-sizing: border-box;
    height: 0;
    overflow-y: hidden;
    transition: all 1000ms;
    background: none;
    padding: 5px 0;
}

.expander main.expanded{
    padding:20px 0;
    height: 220px;
}

.expander p{
    margin-left: 0;
    margin-block-start: 0em;
}