
.layout-modal{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    min-width: unset;
    h3{
        font-size: .9rem;
    }
    .close-modal-button{
        padding: 0 10px;
        z-index: 1000;
        left:unset;
        color:black;
    }
    color: rgb(60, 60, 60);
    top: 150px;
    padding: 0;
    border-radius: 3px;
    border-top: 10px solid midnightblue;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 9000;
    .modal-content{
        font-size: 1rem;
        max-height: 50vh;
        overflow-y: scroll;
        width:fit-content;
        >div{
            width: fit-content;
        }
        >div>div{
            width: fit-content;
        }
        .option-btn-div{
            width: fit-content;
            padding-top:.4rem;
        }
        .color-selector{
            margin:unset;
            margin-bottom: .5rem;
            button{
                width:unset;
            }
            button{
                margin-top: 0;
            }
        }
        select{
            display: block;
            width:150px;
            font-size: .9rem;
            padding:0.2rem;
        }
        input[type="range"]{
            display: block;
            box-shadow: unset;
            width:unset
        }
    }
    .color-picker-modal{
        transform: unset;
    }
}

.open-layout-btn{
    z-index: 200;
    color:white;
    background: #1b84d7;
    border-radius: 1.5rem;
    padding:6px 10px;
    @media screen and (max-width:600px) {
            display: none;
    }
}

