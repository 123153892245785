@import "@/style/mixins.scss";

div.payout-form-cont{
    form{
        padding-top: 40px;
        >div{
            display: flex;
            flex-direction: column;
            label{
                margin-bottom: .5em;
            }
            input,select{
                width:400px;
            }
        }
    }
}