.image-flow{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    >.mobile-image{
        width: 100%;
    }
    >div{
        position: relative;
        img{
            width: 100%;
        }
        .pseudo-padding{
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            border: 3px solid rgba(255, 255, 255, 0.945);
            @media screen and (max-width:600px) {
                display: none;
            }
        }
        .arrow-btn{
            position: absolute;
            z-index: 100;
            top: 50%;
            left:0px;
            opacity: .4;
            &.right{
                left:unset;
                right:0;
            }
            &:hover{
                opacity: 1;
            }
        }
    }

}