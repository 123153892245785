.videos-cont{
    margin:auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    .deleteable-cont{
        div{
            display: flex;
            flex-direction: column;
        }
        .centerer{
            min-height: unset;
        }
        input{
            margin:auto;
            width:clamp(250px,70vw,400px);
            border:1px solid grey;
        }
    }
    .add-video-button{
        margin-top:3rem;
        margin-bottom:3rem;
    }
}