header,.header{
    background: var(--header-bg);
    padding:.1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header.main-header.my-pages-header,header.main-header.filled-in{
    position: sticky ;
    background: #131759;
    z-index: 200;
}

header.main-header{
    position: fixed;
    top: 0;
    background: none;
    opacity: 1;
    z-index: 100;
}

header.main-header.scrolled{
    background: #131759;
    transition: all 1s;
}

.header-left{
    margin:1rem;
    
}

.header-left a{
display: flex;
text-decoration: none;
color:black
}

.header-logo{
    height: 75px;
}

.header-right{
    margin:1rem;
    margin-right:2rem;
    display: flex;
    align-items: center;

}

.header-right button{
    margin:.5rem;
    border-radius: 1.5em;
    font-size: 1.3rem;
}

button.smaller{
    font-size: 1.1rem;    
}

.header-right svg{
    color:rgba(255, 255, 255, 0.532);
    margin-right: 1rem;
}


.login-btn{
    background: none;
    border: none;
    box-shadow: unset;
    color:white
}

header.main-header.my-pages-header img{
    height: 55px;
}

header.main-header.my-pages-header>*{
    margin:10px;
    margin-right: 30px;
}

header.main-header.my-pages-header .header-right button{
    margin:.3rem;
    border-radius: 1.5em;
    font-size: 1.1rem;
}



@media screen and (max-width:500px) {
    header.main-header{
        padding: 0;
    }


    header button{
        scale:.8;
        margin:unset !important;
    }

    .login-btn{
        transform: translateX(20px);
    }

    .splash h1{
        margin-top: 120px;
        font-size: 3rem !important;
    }

    .splash .hero h3{
        text-align: center !important;
        margin-bottom: 1rem !important;
    }

    .splash section.mid,.splash section.how-to{
        padding:1.5rem;
        width: unset;
        display: flex;
        align-items: flex-start;
    }

    .splash section.mid h1{
        text-align: left;
    }

    .header-logo{
        height: 50px;
    }

    .header-left{
        margin:.4rem;
        margin-top:.8rem;
    }
    .header-right{
        margin:.4rem !important
    }

    .splash .footer-style img{
        margin-top: 35px;
    }
}