main.standard section.section.events{
    .events-cont{
    a{
        text-decoration: none;
    }
    }
    padding-bottom: 3rem;
    @media screen and (max-width:800px) {
        padding: 0
    }
}


section.section.events.left__align{
    align-items: flex-start;
    .section-heading,.events-cont{
        width:unset;
        padding-left:10%;
    }
}


