.image-grid{
    background: fixed;
    .image-grid-cont{
        img{
            width:32vw;
        }
    }
    input{
        margin-bottom: 0;
        margin-right: 2rem;
    }
}