@import "./style/mixins.scss";

@font-face {
  font-family: 'Ultra';
  src: url('./fonts/Ultra-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Figree';
  src: url('./fonts/Figtree-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cantarell';
  src: url('./fonts/Cantarell-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Prata';
  src: url('./fonts/Prata-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}


input{
  font-family: BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  max-width: 1200px;
}

.h1,.h2{
  background: none;
  padding:0;
  color:inherit;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  display: block;
  width: unset;
}

.h1{
  font-weight: bold;

}

.h2{
  font-weight: bold;
  width: 80vw;
}

.h3{
  font-weight: 600;
}

body{
  box-sizing: border-box;
}

section.section.z-top{
  z-index: 50;
}

.input-inherit{
    text-align: center;
    font-family: inherit;
    font-size: inherit;
    background: transparent;
    color:inherit;
    padding:0;
    margin-bottom: unset;
    font-weight: inherit;
}

.transparent{
  @include what-you-get
}


// generic classes

section .weight-set-normal{
  font-weight: normal;
}
section .weight-set-bold{
  font-weight: bold;
}
section .weight-set-bolder{
  font-weight: bolder;
}

section .shadowed{
  text-shadow: 2px 1px 0px #888;
}


*{

  ::-webkit-scrollbar {
      width: 20px;
  }
  
  ::-webkit-scrollbar-track {
      background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
      background-color: #d6dee1;
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb:hover {
      background-color: #a8bbbf;
  }
}