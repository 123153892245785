button.round{
    border-radius: 50%;
    width:40px;
    height: 40px;
    padding:5px;
    &.right{
        transform: rotate(180deg);
    }
    &.up{
        transform: rotate(90deg);
    }
    &.down{
        transform: rotate(270deg);
    }
}