@import "@/style/mixins.scss";
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}  
  .custom-text-editor {
    position: relative;
    height: 100vh;
    width: 100%;
    color:black;
    p{
        margin-bottom: 1rem;
    }
    .image-editor-cont{
        width:50%;
        float:left;
        position:relative;
        padding: 0.5rem;

        &.right{
          float:right !important;
        }
        &.full{
          float: unset !important;
          width:100%;
        }
        img{
          width: 90% !important;
          margin:5%;
        }
        .delete-circle{
          display: unset !important;
          transform: unset;
          top: 30px;
          right: 30px;
          width:20px;
          height: 20px;
          padding:unset;
          text-align: center;
          &:hover{
            transform: unset;
          }
        }
        .image-buttons-cont{
        }
        .image-buttons-cont{
          position:absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          button{
            padding:.2em;
            opacity: 1;
            display: unset !important;
          }
        }
    }
    .row {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
  
      .editor {
        display: flex;
        flex-direction: column;
        height: 100%;
        width:650px;
        max-width: 100%;
        .toolbar{
          width:100%;
          border-bottom: 1px solid rgba(128, 128, 128, 0.428);
          padding: .6rem;
          display: flex;
          text-align: center;
          >*{
            margin-right:.5rem !important;
          }
          select{
            margin:unset;
            cursor: pointer;
            display: inline-block;
            padding-left: 8px;
            padding-right: 2px;
            font-size: .7rem;
            box-shadow: unset;
          }
          
        }
        .live-content{
          flex-grow: 1;
          padding:1rem;
          overflow: scroll;
          border:2px solid rgb(129, 122, 122);
          background-color: #fafafa;
        }
        .editor-input{
            height: 100%;
            width:100%;
            .ql-container{
              font-size: 1rem;
            }
        }
      }
    }
    @include quillImages
  }