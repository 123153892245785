.my-pages .card{
    position: relative;
    height: unset;
}

.card h1.website-name{
    position: absolute;
    font-size: 1.1rem;
    padding:1em;
    margin:0;
    color:rgb(49, 47, 47);
    background-color: rgba(241, 241, 241, 0.426);
    cursor: pointer;
}

.card .card-image{
    width: 100%;
    height: 200px;
}

.status-cont{
    text-align: right;
    margin-top: 1em;
    margin-bottom: 0;
    font-weight: bold;
    
}

.status-cont button{
    margin-left: 1rem;
}

.share-icon-cont{
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 0.5rem;
    cursor: pointer;
    background: #0000004f;
    color: #fffefb;
    box-sizing: border-box;
}
.share-icon-cont svg{
    margin-left:10px;
}

.card button{
    width:120px;
}

a.get-assistance-anchor{
    cursor: pointer;
    text-decoration: underline;
}

.cards-cont{
    .assistance-modal.modal{
        top:78px;
        padding:1rem 2.5rem;
        height: calc(100vh - 78px);
        border-radius: 0;
    }
}