.promo-input-cont{
    border: 2px solid black;
}

.modal-content .promo-input-cont input{
    margin:0;
    width: 200px;
    outline: none;
}

.promo-input-cont button{
    padding: 1rem;
    border-radius: 0px;
}

.promo-message{
    padding:.5em;
}