.full-width-add{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px;
    button{
        padding:3px 100px;
        background: none;
        color:inherit;
        border-radius: 0;
        border:#77777777 2px solid;
        box-shadow: unset;
        opacity: .5;
        &:hover{
            transform: unset;
            box-shadow: unset;
            opacity: 1;
        }
    }
}