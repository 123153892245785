.payment-method{
    cursor: pointer;
    box-sizing: border-box;
    padding: .5em;
    display: flex;
    justify-content: space-between;
    margin:.5rem;
    border-radius: 5px;
}
.selected{
    border:solid #c4c166 2px;
    background: #c4c16634;
    cursor: unset;
}

.brand{
    font-weight: 700;
    margin-right: 30px;
}

.payment-method .brand::before{
    content: "\25EF";
    margin-left: 14px;
    margin-right: 24px;
}

.payment-method.selected .brand::before{
    content: "\26AB";
    margin-left: 10px;
    margin-right: 20px;
}

@media screen and (max-width:500px) {
    .payment-method{
        margin:10px 0;
    }
    .payment-method .right>span:nth-child(2){
        display: block;
        margin-left: 3.2rem;
    }
}