.team-member-cont{
    position: relative;
    .delete-circle-button{
        font-size: 2rem;
    }
}


.team-member{
    width:90%;
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    position: relative;
    column-gap: 30px;
    margin:auto;
    .h1
    {
        display: grid;
        grid-column: 1;
        grid-column-end: 3;
        font-size: 2rem;
        width:90%;
        line-height: 1.3em;

    }
    .overlay{
        display: none;
    }
    img{
        width: 300px;
    }
    .socials{
        width: 300px;
        margin:auto
    }
        .p{
            margin-top: 0;
            font-size: 1.5rem;
            width:500px;
            max-width: 85vw;
        }
        textarea{
            min-height: 250px;
            width: 100%;
        }

    margin-bottom: 40px;
    @media screen and (max-width:800px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: unset;
        img{
            width: 200px;
            margin-bottom: 20px;
        }
        .socials{
            width: 200px;
        }
        .p{
            margin-top: 10px;
            font-size: 1rem;
            padding:10px;
            text-align: inherit;
        }
        .h1{
            text-align: center;
            max-width: 90%;
        }
    }
    .socials{
        padding:1rem 0rem;
        a{
            padding:.4rem;
        }
        .empty{
            color:#eba6a6;
        }
    }
}


.team-member.layout-set-right{
    width:unset !important;
    grid-template-columns: unset;
    .h1{
        font-size: 3rem;
        // text-align: center;
        width:100%;
    }
    .p,.auto-textarea-cont{
        grid-row:2/3
    }
    .socials{
        grid-column: 1/3;
        padding-top:50px;
    }
}