header.black{
    background: black;
    position: static;
}

.forgot-password{
    h2{
        margin-bottom:2rem;
    }
    input{
        margin-top:1rem;
    }
    padding-top: 60px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.forgot-password form{
    /* display: inline; */
    margin:auto;
    display:flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.forgot-password form>div{
    display: flex;
    flex-direction: column;

}

.forgot-password form input{
    border-bottom: 2px solid black;
    border-radius: 0;
}

.forgot-password .recaptcha-cont{
    margin:20px
}

main.success{
    padding:40px;
    padding-top:100px;
    h2{
        margin-bottom: 20px;
    }
    padding: 40px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}