@import "@/style/variables.scss";
@import "@/style/mixins.scss";

.section-request-main{
    max-width: 1000px;
    margin:auto;
    background: $offer-white;
    min-height: 100vh;
    display: flex;
    padding:50px;
    justify-content: center;

    form{
        >div{
            display: flex;
            flex-direction: column;
            label{
                margin-bottom: 1rem;

            }
            input,select,textarea{
                width:600px;
                max-width: 90vw;
            }
        }
    }
    .section-request-success{
        @include flex-down;
        h2{
            margin-bottom: 40px;
        }
        p{
            margin-bottom: 40px;
        }
        footer{
            button{
                display: block;
                width: 230px;
                margin-bottom: 10px;
            }
        }
    }
}

