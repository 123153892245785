.modal.notification.expanded{
    padding-top:0;
    background: rgb(255 255 255);
    width: 90vw;
    max-width: 500px;
    top:50%;
    transform: translate(-50%,-100%);
}

.notification-content{
    margin-right:30px;
    margin-top:60px;
    font-size: 1.3rem;
    color:rgb(59, 58, 58);
}
