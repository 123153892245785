div.middle-stack{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    >*{
        margin:1em auto;
    }
}