.tiktok-embed-wrapper{
    margin:auto;
    display: flex;
    justify-content: center;
    height: 570px;
    width:320px;
    overflow: clip;
    background: transparent;
    iframe{
        width: 100%;
        overflow: clip;
        *{
            overflow: clip;
        }
    }
}