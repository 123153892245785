@import "@/style/mixins.scss";


header.standard.private{
    background:none;
    >header{
     position: sticky;
            z-index: 1500;
    }
    .owners-nav{
        background:var(--header-bg);
    }
    .heading{
        z-index: 0;
    }
    .dynamic-nav-header{
        position: fixed;
        transform: translateY(53px);
        .heading{
            padding-top: var(--verticalPadding);
            padding-bottom: var(--verticalPadding);
            position: relative;
            .edit-img-cont{
                height: 100%;
                position: unset;
                background: none;
                &.loaded{
                    position: relative;
                    .delete-circle{
                        bottom:0;
                        top:unset;
                        right:0;
                        padding:0;
                        svg{
                            display: block;
                            width: 12px;
                            height: 12px;
                            margin: 10px;
                        }
                        @include tooltip-down
                    }
                }
                .add-logo-button{
                    left:0;
                    top:0;
                    width:unset;
                    transform: translate(-100%,-57%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 2.5em;
                    width:2.5em;
                    border-radius: 50%;
                    @include tooltip-down-right
                }
            }
            img{
                margin:10px;
                cursor: pointer;
            }
        }
    }
    .dynamic-nav-header.sticky{
        transform: unset;
        position: sticky;
    }
}

.dynamic-nav-header{
    background-color: black;
    color: rgb(247, 241, 241);
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: var(--flex-direction);
    height: fit-content;
    padding-top:var(--verticalPadding);
    padding-bottom:var(--verticalPadding);
    position: fixed;
    --imageHeight:50px;
    &.sticky{
        transform: unset;
        position: sticky;
    }
    nav{
        display: block;
        font-size: 1.1rem;
        z-index: 4;
        ul{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            margin-block-start: 0;
            padding-inline-start: 0px;
            margin:unset;
            list-style: none;
            li{
                padding:.5rem;
                a{
                    color:inherit;
                    text-decoration: none;
                    padding:.5em;
                    box-sizing:border-box;
                    opacity:.9;
                    font-size: var(--linkSize);
                    &:hover{
                        opacity: 1;
                    }
                }
            }
            .color-btn{
                margin-left:20px;
            }
            .inline-button{
                display: inline;
                position: relative;
                left:0;
                transform: unset;
                margin-left: .8rem;
                margin-right: .8rem;
                position: unset;
                color: white;
                background: #1b84d7;
                border-radius: 1.5rem;
                padding: 6px 10px;
            }
            .modal.layout-modal{
                top:130px;
                transform: translateX(-50%);
                .modal-content{
                    max-height: 68vh;
                }
                .color-picker-modal{
                    top:unset;
                    left:unset;
                }
            }
        }
    }

    .heading{
        font-size: var(--headingSize);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: var(--header-name-fd);
        padding:0 1em;
        .edit-img-cont>img,>img,.edit-img-cont img{
            height: var(--imageHeight);
        }
        .edit-img-cont{
            background: none;
            .delete-circle{
                right: -40px;
                top: -20px;
                color:black;
                transform: unset;
                opacity:.95;
            }
            .overlay{
                display: none;
            }
        }
    }
}

.mobile-dynamic-nav-header{
    background:black;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    button{
        color:white;
        background:black;
        border:none;
        box-shadow: none;
    }
    .dropdown{
        position: absolute;
        top:0;
        background: black;
        left:0;
        right:0;
        overflow: hidden;
        padding-bottom: 2rem;
        padding-top:2rem;
        ul{
            display:grid;
            grid-template-columns: 1fr 1fr;
            list-style: none;
            color:white;
            li{
                padding:.7em;
                font-size: 1.4rem;
                a{
                    color:inherit;
                    opacity:.9;
                    text-decoration: none;
                }
            }
        }
    }
}
.scroll-active {
    transition: all 40s ease-in-out;
}
  
header.standard.public{
    position: relative;
    .dynamic-nav-header{
        position: fixed;
        transform: translateY(0);
        bottom:unset
    }
}
  
  
  