header.standard{
    background: var(--header-bg);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    padding:0;
}

header.standard a{
    text-decoration: none;

}

header.standard h3{
    margin: 0;
}

.toggle-cont{
    display: flex;
    align-items: center;
}

.toggle-cont div{
    padding:0 10px;
}

@media screen and (max-width:500px) {

    header .toggle-cont{
        padding:10px 5px;
    }
    header.standard button{
        background: #80808008
    }
    .own-standard-header a{
        font-size: 2rem;
        text-decoration: none;
        margin-left: 1rem;
        color:grey;
        display:flex
    }
    .own-standard-header.editing .toggle-cont div{
        display: none;
    }
}