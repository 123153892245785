@import "@/style/mixins.scss";

.premium-template-header{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    align-content: center;
    padding:20px 40px;
    z-index: 101;
    input{
        margin-bottom: 0;
    }
    .header-btn-cont{
            justify-self: end;
    }
}


.template-lister{
    @include flex-down;
    background: rgb(248, 252, 246);
}