@import "@/style/mixins.scss";


.free-image-modal-overlay{
    width:0;
    height: 0;
}

.free-image-modal{
    @include absolute-center
}

.free-img-cont{
    width:700px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
    color:black !important;


}

.single-img-cont{
    background: rgba(25, 24, 24, 0.073);
    height: 100%;
    box-sizing: border-box;
}

.selected-image-cont{
    border:10px solid rgb(156, 173, 20);
}

.edit-img-cont .modal.free-image-modal{
    top: unset;
    bottom:10px;
    max-height: 85vh;
    overflow: scroll;
}

.free-img-cont .single-img-cont img{
    max-width: 100%;
    height: 150px !important;
    max-width: unset;
    width: 100%;
    max-height: 150px;
    margin:0 !important;
}

.free-img-modal .modal-content{
    padding-top: 20px;
    display: unset;
    max-height: 60vh;
}

footer.free-images-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color:rgb(64, 64, 64) !important;
}

section.section .free-image-modal{
    top:80px;
}

section.section .free-image-modal img{
    min-height: unset;
}