.edit-button-generic{
    position: absolute;
    right:20px;
    top:20px;
    border-radius: 50%;
    opacity: .5;
    background: rgb(136, 168, 199);
    &:hover{
        opacity: 1;
    }
}