.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #1d171734;
    z-index: 2300;
}

.modal{
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top:50px;
    box-shadow: 3px 3px 5px 1px #2e2e2ec4;
    transform: translateX(-50%);
    background: rgb(243, 243, 243);
    padding: 2rem;
    padding-top:50px;
    min-width: 300px;
    &.center-pos{
        top:50%;
        transform: translate(-50%,-50%);
    }
}

.standard .modal{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.modal.sidebar{
    width:100vw;
    max-width: 0px;
    top: 78px !important;
    right:0;
    left:unset;
    transform: unset;
    height: 100vw;
    border-radius: 0px;
}

.modal.sidebar.expanded{
    max-width: 600px;
    transition: max-width 300ms;
}

.modal.sidebar .close-modal-button{
    font-size: 2rem !important;
}

.modal .close-modal-button{
    position: absolute;
    top: 0px;
    right: 0px;
    box-shadow: none;
    font-size: 2rem;
    background: none;
}

.modal-content{
    padding-top:0
}

.modal-content h1{
    margin-top:0
}

@media only screen and (max-width: 600px){
    
}

main.standard.modal{
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    
}