

.hero>section{
    background-color: unset !important;
    height: 600px;
    position: relative;
    padding: 0;
    margin: auto;
    width: 100vw;
}

section.hero .image-cont{
    display: flex;
    width:unset;
}

.hero>section>img.hero-img{
    width: 100vw;
    height: 600px;
    overflow:hidden;
    top:0;

    /* position:absolute; */
}

main.standard section.section{
    width:100%;
    box-sizing: border-box;
    padding:unset;
    max-width:100vw;
}


.hero>section.overlay{
    top:0;
    position:absolute;
    width: 100vw;
    height: 600px;
    background-color: hsla(294, 100%, 16%, 0.564);
}



.hero>section>.content{
    top:0;
    position:absolute;
    width: 100vw;
    max-width: 1200px;
    height: 600px;
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    width: 90vw;
    align-items: flex-start;
    justify-content: center;
    z-index: 10;
}

.hero>section>.content>h1{
    color:#f0f8ffa8;
    font-size: 3.3rem;
}

.hero>section>.content>h3{
    color:#f0f8ffa8;
    font-size: 1.4rem;
    text-align: left;
    max-width: 500px;
    font-weight: 500;
}

.hero>section>.content>button{
    margin-top: 30px;
    background: rgb(239, 231, 6);
    font-size: 1.5rem;
    color: rgb(51, 4, 54);
    opacity: .9;
}

.hero>section>.content>button:hover{
    opacity: 1;
    padding: .7em 1.3em
    
}

@media only screen and (max-width: 600px) {
    .hero>section{
        height: 450px;
    }
    .hero>section>img.hero-img{
        height: unset;

    }
    .hero h1{
        margin-bottom: 0;
    }

    .hero>section>.content{
        height: unset !important;
        padding-left: 0 !important;
    }

    .hero button{
        margin:50px auto
    }

  }