


.edit-section-header{
    position: absolute;
    display: flex;
    flex-direction: row;
    width:100%;
    justify-content: space-between;
    z-index: 3000;
    height: 0;
    overflow: visible;
    >div{
        margin-top: .5rem;
    }
    .toggles-cont{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right:1rem;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: .8rem;
        font-weight: 600;
        >*{
            
            margin-bottom: .5rem;
        }
    }
}