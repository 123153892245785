main.standard section.section.text{
    main.text-main-centerer{
        max-width: 800px;
        margin:auto;
        padding:0 20px;
        padding-top: clamp(30px,4vw,100px);
    }
    .heading_class{
        font-size: 2.5rem;
        font-family: "Oswald";
        text-transform: uppercase;
        margin-top:1em;
        margin-bottom: .7em;
        color:#ff6900;
        width:80vw;
        max-width: 1000px;
        font-weight: 600;
        text-align: center;
        textarea{
            width:100%;
            height:4em;
        }
    }
    .text_class{
        max-width: 1000px;
        font-size: clamp(1rem,2vw,1.5rem);

        line-height: 1.6em;
        text-align: left;
        color:rgb(55, 55, 55);
        textarea{
            width:100%;
            height: 50vh;
        }
    }
    padding-bottom: 80px;
}