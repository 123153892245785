footer.page-footer{
    background:#3e3d3b;
    padding:clamp(30px,5vw,50px);
    display: grid;
    grid-template-columns: repeat(3,1fr);
    h4,.h4{
        font-family: oswald;
        color:#aae1cc;
        text-transform: uppercase;
        font-size: 1.4rem;
    }
    p,.p{
        color:grey;
    }
    .center{
        background: none;
        align-items: flex-start;
    }
    ul{
        li{
            list-style: none;
            text-transform: uppercase;
            color: gray;
            font-size: .84rem;
            margin-bottom: .5em;
            cursor: pointer;
        }
    }
    .right{
        h4{
            color:white;
        }
        .socials{
            justify-content: flex-start;
            >*{
                margin:20px 20px 20px 0;
                color:gray;
                cursor: pointer;
            }
            a{
                padding:unset
            }
        }
    }
}