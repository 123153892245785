.text-block{
    min-height: 400px;
    padding: 0px;
    font-size: 2vw;
}

.text-block p,.text-block .p {
    margin-block-start: 0px;
    margin: 80px auto;
    font-size: inherit;
    text-align: center;
    line-height: 2em;
    width: 800px;
    max-width: 80vw;
    background: none;
    font-family: inherit;
    margin: 3.5rem;
    text-align: center;
}

@media screen and (max-width:1200px) {
    .text-block p,.text-block .p{
        font-size: 2rem;
    }
}

@media screen and (max-width:700px) {
    main.standard .text-block p,.text-block .p{
        font-size: 1.5rem;
    }
}