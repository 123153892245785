.assets-thumbnails{
    display: flex;
    width:600px;
    max-width: 90vw;
    flex-wrap: wrap;
    img,.add-box{
        width:130px;
        margin:10px;
        border: solid 3px black;
    }
    .add-box{
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover{
            background: rgb(211, 209, 209);
        }
    }
}