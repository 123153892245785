.links-cont{
    margin-top: 0;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: italic;
    @media screen and (max-width:600px) {
        margin-bottom: 20px;
    }
}