.review-cont{
    position: relative;
    height: fit-content;
    width: 300px;
    height: 450px;
    margin:2rem;
    padding:1rem;
    background: #8c8c8c2e;
    color: #2221219c;
    border-radius: 10px;
    flex-wrap: wrap;
    >div.hidden-overflow{
        overflow: hidden;
        height: 100%;
    }
    @media screen and (max-width:500px) {
        height: fit-content;
    }
    &.expand{
        height: fit-content;
    }
    &.fade>.cont.review::after{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100px;
        background-image: linear-gradient(to top, rgba(196, 181, 181, 0.883) 0%, rgba(255, 255, 255, 0) 100%);
        pointer-events: none;
        opacity: 1;
        transition: opacity 0.3s;
      }
    .cont.review{
        display: flex;
        flex-direction: column;
        >.img{
            display: none;
        }
        &.editing{
            height: 100%;
            .p{
                flex-grow: 1;
            }
        }
    }
    .edit-img-cont{
        display: none;
    }
    .h1{
        font-size: 1.3rem;
        font-style: italic;
    }
    .h3{
        font-size: 1.1rem;
        font-style: italic;
        padding:0;
        background: none;
        margin-bottom: 1em;
    }
    .p{
        font-size: 1rem;
        width: 100%;
    }
    .expander-btn{
        position: absolute;
        bottom: 10px;
        right: 20px;
        div{
            &::before{
                background-color: white;
            }
            &::after{
                background-color: white;
            }
        }
    }
}