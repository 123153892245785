@import "@/style/mixins.scss";
main.standard section.custom,main.standard section.custom-template{
    @include resets;
    box-sizing: border-box;
    &.full-height{
        width: calc(100vw - 20px);
        .container{
            width: calc(100vw - 20px);
        }
    }
    min-height: 100px;
    .container{

        box-sizing: border-box;
        .h2{
            width:unset
        }
        p{
            font-size: unset;
            line-height: unset;
        }
        .e-textarea{
            font-size: inherit;
        }
        h1,h2,h3,h4,h5{
            font-size: unset;
        }
        .edit-img-cont{
            margin:unset;
        }
        button{
            *{
                border:none !important;
                padding: 0 !important;
                margin:0 !important;
            }
        }
        iframe.video{
            height: 200px;
        }
    }
    .centerer{
        min-height: unset;
    }
}