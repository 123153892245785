@import "@/style/mixins.scss";

.assistance-main{
    width:400px;
    max-width: 90vw;
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2{
        margin-bottom: 1em;
    }
    p{
        margin-bottom: 1em;
    }
    .btn-cont{
        width: 100%;
        display: flex;
        justify-content: center;
        button{
            margin:auto;
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
}

.assistance-main-set{
    button{
        width: 100%;
        margin-bottom: 1rem;
    }
}