main.standard section.section.video{
    padding-bottom: 1rem;
}

main.standard section.section.video input[name='url']{
    margin-top: 2rem;
    width:80vw;
}

.centerer{
    min-width: 350px;
    width:80vw;
    max-width: 1000px;
    min-height: 400px;
}

.video-cont{
    overflow: hidden;
    padding-bottom: 56.25%;
    position:relative;
    height: 0;
    width: 100%;
    margin-bottom: 2rem;
}

.video-cont iframe{
    left:0;
    top:0;
    height: 100%;
    position: absolute;
    width: 100%;
}

@media screen and (max-width:500px) {
    .centerer{
        min-width: 350px;
        width:100vw;
        max-width: 1000px;
        min-height: 400px;
    }

    .video-cont{
        border-width: 10px 0 10px 0;

    }
    .centerer{
        min-height: unset;
    }
}