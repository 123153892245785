.e-video{
    position: relative;
    .input-and-label-container{
        display: none;
        position: absolute;
        width: 100%;
        bottom: 4px;
    }
    &:hover{
        .input-and-label-container{
                background: white;
                display: flex;
                flex-direction: row;
                width: 100%;
                padding: 10px;
                background: #ededed;
                align-items: center;
        >input{
            flex-grow: 1;
            margin-bottom: 0;
            padding:4px;
            border-radius: 0;
        }
        >label{
            margin-bottom: 0;
            margin-right: 10px;
        }
        }
    }
}