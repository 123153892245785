header.section-jobs-header{
    height: 56px;
    background:rgb(11 11 94);
    color:rgb(245, 245, 245);
    box-shadow: 2px 0px 5px #cdcdcd;
    img.logo{
        width:50px;
    }
    h3{
        font-size: 1.4rem;

    }
    .back-btn{
        margin-right: 20px;
        background: white;
        border-radius: 0;
        padding:.4rem 1rem;
    }
}



main.jobs-main{
    background:#dae0e6;
    min-height: 100vh;
    .search-bar{
        margin:20px;
        input{
            border-radius: 0;
            width: 300px;
        }
    }
    section.section-jobs-board{
        max-width: 98vw;
        width: 1000px;
        margin: auto;
        padding-top:35px;
        article{
            display: flex;
            aside{
                width:80px;
                padding:10px;
                font-size: 1.1rem;
                font-weight: bold;
                background: rgb(141 141 224);
                color: rgb(255 255 255);
            }
            >section{
                padding:10px;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                h5{
                    font-weight: 500;
                    font-style: italic;
                }
                h3{
                    padding:.5em;
                    padding-left:0;
                    cursor: pointer;
                }
                .view-current{
                    margin-top: 1rem;
                    align-self: flex-end;
                }
                a{
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
            margin:20px;
            background: white ;
        }
    }
}


.job-details-modal-overlay{
    background: rgba(0, 0, 0, 0.9);
    .modal.job-details-modal{
        top: 40%;
        transform: translate(-50%,-50%);
        padding: 12px 50px 10px 20px;
        border-radius: 0;
        .close-modal-button{
            padding: 0px 10px;
        }
        h3{
            margin:.7em 0;
            font-size: 1.5rem;
            text-align: center;
            translate: 15px;
        }
        p{
            margin: 1rem 0;
            &.request-disclaimer{
                font-style: italic;
            }
        }
        button{
            border-radius: 0;
        }
        img{
            margin:20px;
            margin-left:0;
            width:100px;
        }
    }
}