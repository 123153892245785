.videos-cont{
    .deleteable-cont{
        button{
            top:0;
            right:0;
            transform: unset;
            border-radius: 0;
        }
    }
}


.edit-video-cont{

    // background:rgb(243, 242, 242);
    border:dashed 2px grey;
    margin-bottom:1rem;
    input{
        padding:6px;
    }
    .video-cont{
        margin-bottom: 2px;
    }
}