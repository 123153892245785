@import "@/style/variables.scss";

div.payout{
    header{
        color:$offer-white;
        justify-content: flex-start;
        h1{
            margin-left: 40px;
        }
    }
    main{
        >button{
            margin: 20px auto;
        }
        max-width: 800px;
        margin: auto;
        padding-top: 50px;
    }
}