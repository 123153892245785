section.cta{
    background-color: rgb(244, 244, 249);
    .cta-grid{
        padding-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        h3{
            margin-right: 2rem;
            margin-top: 1em;
            margin-bottom: 1em;
            font-weight: 700;
            color: rgb(81, 80, 80);
        }
        button{
            background:blue;
            color:white;
        }
        @media screen and (max-width:650px) {
            flex-direction: column;
        }
    }

}