.standard section.hero{

    .text-cont{
        &.mobile{
        padding:30px;
        transform: translateY(-50%) !important;
    }
    .h1{
        margin-bottom: 1rem;
        line-height: 1em;
    }
    .h2{
        line-height: 1em;
    }
    textarea{
        padding:0;
    }
}
}

section.section.hero{
    .full-width.mobile{
        min-height: 70vh;
        object-fit: cover;
        object-position: 50%;
    }
    .full-page-middle-scroller{
        z-index: 3000;
        width:100vw;
        overflow: unset;
        section{
            width:100%;
            max-width: unset;

        }
    }
}