.footer-style{
    background: #131759;
    color:white;
}

.footer-style section.section{
    padding:unset;
    background: #131759;
    color:white;
    width: unset;
    padding-bottom: 30px;
}

.splash .footer-style section.section{
    padding-bottom: 30px;
}

.footer-style p{
    color:white;
    grid-row:3;
    grid-column-start: 1;
    grid-column-end: 5;
}