@import "@/style/mixins.scss";

header.agreement{
    position: sticky !important;
    background: rgb(12 50 112) !important;
}

.user-agreement{
    padding:30px;
    @include horizontal-center-page;
    h1{
        margin-bottom:1em;
    }
}
.user-agreement p {
    font-size: 1.2rem;
    margin:1rem 0;
}
.user-agreement li{
    font-size: 1.1rem;
    line-height: 1.7em;
}