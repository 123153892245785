.edit-box{
    background:#eeeeee;
    padding:1rem;
    padding-bottom: 3rem;
    border-radius: 5px;
    color:#222222;
    >div{
        display: flex;
        flex-direction: column;
        >div{
            display: flex;
            flex-direction: column;
            align-items: start;
            input{
                margin-bottom: .3em;
            }
        }
        margin-bottom: 1rem;
    }
    button{
        float: right;
    }
}