.section-request-card{
    background: rgb(242, 243, 245);
    display: grid;
    grid-template-columns: auto 300px;
    padding:1rem;
    color: rgb(37, 41, 61);
    width:90vw;
    max-width: 600px;
    margin:2rem;
    >div{

        h3{margin-bottom: .1em;}
        h5{
            margin-bottom: 1rem;
            font-weight: 400;
            font-style: italic;
        }
    }
    div.right{
        justify-self: flex-end;
    }
    .description{
        grid-column: 1/3;
        p{
            margin:1rem auto;
        }
    }
    footer{
        grid-column: 1/3;
        p{
            a{
                cursor: pointer;
                text-decoration: underline;
            }
            margin-bottom: .5em;
        }
        .pending-count{
            font-style: italic;
        }
    }
}