.full-screen{
    color:#eeeeee;
    height: 100vh;
    width: 100vw;
    background: black;
    animation: fade-in 4s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title{
        font-size: clamp(1.5rem,4vw,3rem);
        // animation: shake 2s infinite;
        h1{
            animation:flash 4s infinite;
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-2px) rotate(-0deg); }
    40% { transform: translateX(2px) rotate(0deg); }
    60% { transform: translateX(-2px) rotate(-0deg); }
    80% { transform: translateX(2px) rotate(0deg); }
    100% { transform: translateX(0); }
  }

@keyframes flash {
    0%{
        color:inherit
    }
    50%{
        color:rgba(255, 255, 255, 0.613)
    }
    100%{
        color:inherit
    }
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
  }
  .wave {
    width: 10px;
    height: 200px;
    background: linear-gradient(45deg, rgb(239, 241, 152), #fff);
    margin: 30px;
    animation: wave 1s linear infinite;
    border-radius: 20px;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  .wave:nth-child(7) {
    animation-delay: 0.6s;
  }
  .wave:nth-child(8) {
    animation-delay: 0.7s;
  }
  .wave:nth-child(9) {
    animation-delay: 0.8s;
  }
  .wave:nth-child(10) {
    animation-delay: 0.9s;
  }
  
  @keyframes wave {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }