.image-drop-modal{
    padding-top:2rem;
    color:black;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1rem;
    button{
        font-size: .9rem;
        border-radius: 10px;
        &.close-modal-button{
            padding:0 .5rem;
        }
    }

    .modal-content{
        font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        text-transform: initial;
        .drop-area{
            width:400px;
            margin:auto;
            position: relative;
            color:grey;
            .dotted-div{
                top:20px;
                bottom:20px;
                left:20px;
                right:20px;
                position: absolute;
                border:8px rgb(181, 181, 181) dashed;
            }
            h2{
                font-family:sans-serif;  
                font-size: 1.3rem;
                
            }

        }
        .free-image-modal-overlay{
            .modal{
                top: 0;
                left: 50%;
                transform: translateX(-100%);
            }
        }
        .file-chooser-modal-overlay{
            width: 100%;
            height: 100%;
            .file-chooser-modal{
                color:rgb(29, 29, 29);
                left:50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .bottom{
        >div{
            display: flex;
            justify-content: center;
            align-items: center;
            grid-gap: 5px;
            width: 445px;
            >button{
                margin-top: 0;
            }
        }
        .url-div{
            margin-top: 1rem;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding-left: .5rem;
            label{
                margin-right:0rem;
                font-size: 1rem;
                font-weight: 400;
                font-family: Arial, Helvetica, sans-serif;
                // font-family: fangsong;
            }
            input{
                // margin:1rem;
                width: 100%;
            }
        }
        button.danger{
            display: block;
            margin: auto;
        }
    }
    .free-image-modal-overlay{
        background: transparent;
        .modal.free-image-modal{
            bottom:unset;
            overflow: unset;
        }
    }
}


.edit-img-cont{
    position: relative;
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    >div{
        width:100%;
        max-width: 100vw;
    }
    &.no-max{
        max-width: unset;
    }


    .edit-buttons-cont{
        display: none;
        top: 0;
        left: 50%;
        transform: translate(-50%,0%);
        opacity: 1;
        position: absolute;
        width: -moz-fit-content;
        width: fit-content;
        z-index: 100;
        .delete-circle{
            position: relative;
            display: unset;
            transform: unset !important;

        }
    }
    &:hover{
        .edit-buttons-cont{
            display: flex;

        }
    }
}

.edit-img-cont img,.edit-img-cont .drop-area{
    width: inherit;
    max-width: inherit;
}

.edit-img-cont.dropable{
    border-radius: 15px;
    .add-logo-button{
        position: absolute;
        left:50%;
        top:0;
        transform: translate(-10%,-250%);
        z-index: 4000;
        width:120px;
        opacity: .4;
        padding:0;
        &:hover{
            opacity: 1;
        }
    }
}

.drop-area{
    height: 80vw;
    max-height: 300px;
    background: inherit;
    color:inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}
.drop-area.highlight, .drop-area:has(.dotted-div.highlight){
    background: black;
}

.drop-area h2{
    width: 60%;
}

.edit-section .modal-content h1{
    font-size:1.5rem !important ;
}

.edit-section .modal .close-modal-button{
    right: -140px;
    background: none;
}


.modal-content input{
    width: 300px;
    font-size:1rem !important ;
}

.edit-img-cont .btn-primary{
    text-transform: unset;
    letter-spacing: 0em;
    margin-bottom: 1em;
    padding: 0.7em;
}

.open-file-modal-btn{
    background: #d3c9b8;
    margin-top: 1rem;
    border-radius: 10px;
}

.edit-img-cont .modal{
    top:50%;
    transform: translate(-50%,-50%);
}

.edit-img-cont .modal form{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.edit-img-cont .modal form input{
    background: #00000014;
    width: 350px;
}

.edit-img-cont .modal form button{
    margin-top: 10px;
}

.edit-img-cont.dropable>button{
    margin:1rem;
}

.edit-img-cont.dropable>input{
    width: 400px;
}


.edit-img-cont.dropable button.delete-circle{
    z-index: 900;

}

@media screen and (max-width:600px) {
    .drop-area{
        display: none;
    }
    .edit-img-cont.dropable>input,.edit-img-cont.dropable>button{
        width:90vw;
        max-width: unset;
    }
    .edit-img-cont.dropable>button.delete-circle{
        width:unset;
        font-size:.8rem;
    }
}