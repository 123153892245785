@import "@/style/mixins.scss";

.template-validation-modal-overlay{
    opacity: 1;
    background: rgba(0, 0, 0, 0.904);
    .template-validation-modal{
        @include sleek;
        border-radius: 0;
        top:40%;
        transform: translate(-50%,-50%);
        input,select{
            padding:.4rem;
            font-size: 1rem;
        }
        .close-modal-button{
            padding:0 10px;
        }
        button.submit{
            width: 100%;
            padding:.5rem;
            background:rgb(6 96 24);
            color: white;
        }
    }
}