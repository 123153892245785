@import "@/style/variables";

header.article-header{
    background: $dark-blue;
}


.article-main{
    padding-top: 140px;
    padding-bottom: 60px;
    width: $mid-width;
    margin:auto;
    p{
        font-size: 1.2rem;
        line-height: 1.7em;
    }
}