section.short-cont{
    margin-bottom:1rem;
    max-width: 800px;
    width:95vw;
    box-shadow: 2px 2px 4px grey;
    $color:rgb(58, 57, 57);
    .top{
        display: flex;
        position: relative;
        $height:clamp(100px,15vh,150px);
        height: $height;
        img{
            height: $height;
        }
        .mid-top{
            padding:clamp(.4rem,3vw,1rem);
            padding-bottom: clamp(2rem,3vw,1rem);
            overflow: hidden;
            flex-grow: 1;
            .date{
                span{
                    margin-right:2em;
                }
            }
            h3{
                font-size: clamp(1rem,3vw,1.5rem);
            }
        }
        button{
            background:none;
            border-radius: 50%;
            height: 43px;
            width: 40px;
            border:5px solid $color;
            color:$color;
            align-self: center;
            margin:1rem;
            svg{
                transform: translate(-5px,-1px);
            }
        }
        .expand-btn{
            position: absolute;
            bottom:0px;
            left:50%;
            width: 30px;
            cursor: pointer;
        }
    }
    .expansion{
        height: 0px;
        transition: height .5s ease;
        overflow: hidden;
        &.expanded{
            height: auto;
        }
        .description{
            padding:2rem;
            p{
                font-size: 1rem;
            }
        }
    }
    &.mobile{
        .top{
            .mid-top{
                h3{
                    font-size: .8rem;
                }
            }
            button{
                scale:.7;
                margin:.4rem;
            }
        }
    }
}