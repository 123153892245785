.modal.share-modal{
    hr{
        margin-bottom: 1rem;
    }
    .socials{
        padding-left: 0;
        margin-bottom: 1rem;
    }
}



.modal.share-modal{
    top:30%;
    min-width: 300px;
    padding-top:1rem;

}

.share-modal .socials{
    justify-content: unset;
    padding-left: 0px;
    margin-top:30px;
}

.share-modal .social-icon{
    color:rgb(0, 115, 255); 
    width: 35px;
    transform: scale(1.4);
}

.copy-cont{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copy-cont input{
    margin:auto
}

.share-modal button{
    padding:.85rem;
}

