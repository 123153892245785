section.article-edit{
    form{

        textarea,input{
            border:2px solid grey;
        }
        textarea{
            height: 600px;
            width:800px;
        }
    }
}