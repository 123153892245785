main.settings-main .settings{
    display: block;
    width: unset;
    max-width: 1200px;
    margin:auto;
    padding-top: 40px;
    background-color: rgba(242, 242, 242, 0.313);
    min-height: 100vh;
    position: unset;
}

main.settings-main section.section.settings h1{
    text-align: left;
}