.text-editor-header{
    background: unset;
    .input-cont{
        width: 650px;
        max-width: 100%;
        margin:auto;
        display: flex;
        flex-direction: column;
        align-items:unset;
        .input-and-label-container{
            display: flex;
flex-direction: row ;
            align-items: center;
            label{
                margin:0;
                width:100px;
            }
            input{
                padding:.2rem;
                max-width: 400px;
            }
        }
    }
}