.confirm-page{
    max-width: 600px;
    width: 80vw;
    margin:200px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.confirm-page h1{
    margin-bottom: 3rem;
}
.confirm-page h3{
    font-weight: 400;
}
.confirm-page button{
    margin:2rem auto
}