.editor-button-small{
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: unset;
    display: flex;
    justify-content: center;
    background: #2074bf;
    color: white;
    align-items: center;
    opacity: 1;
}