.color-grid{
    display: grid;
    grid-template-columns: repeat(8,1fr);
    // grid-column-gap:1px;
    // grid-row-gap: 5px;
    padding:10px;
    .color-cell{
        width:50px;
        height: 50px;
        cursor: pointer;
        &.selected{
            box-sizing: border-box;
            border: 4px solid rgb(50, 50, 50);
            border-bottom:4px solid rgb(161, 161, 161);
            border-right:4px solid rgb(161, 161, 161);
            position: relative;
            &::after{
                content: "\2022";
                position: absolute;
                left:50%;
                top:50%;
                transform: translate(-50%,-60%);
                font-weight: 800;
                font-size: 150%;
            }
            &.dark::after{
                color:white;
            }
        }
    }
}