main.dev-center-main{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    .action-items{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: #f6f6f6;
        h2{
            margin-bottom: .5rem;
        }
    }
}