@import "@/style/mixins.scss";

main.standard section.section.blog{
    p{
        font-size: 1.2rem;
        width:50%;
        display: inline;
    }
    @include quillImages;
    padding-bottom: 70px;
    .image-editor-cont{
        width:50%;
        cursor: grab;
        button{
            display: none;
        }
        &.left{
            float: left;
        }
        &.right{
            float:right
        }
        &.full{
            width:100%
        }
        img{
            width: 90%;
            margin:5%;
        }
    }

}