@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Open+Sans&family=Montserrat&family=Lato&family=Oswald&family=Raleway&family=Source+Sans+Pro&family=PT+Sans&family=Ubuntu&family=Slabo+27px&family=Playfair+Display&family=Lora&family=Merriweather&family=Noto+Sans&family=PT+Serif&family=Crimson+Text&family=Josefin+Sans&family=Bitter&family=Arimo&family=Vollkorn');

*{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --header-bg:rgb(243, 243, 243);
  --neutral-bg:#fcfcfc;

  --button-primary-bg:#1f9741;
  --button-secondary-bg:hsl(272, 60%, 45%);
  --button-danger-bg:rgb(196, 31, 31);
  --dark-text:#262525;
  --green-text:var(--button-primary-bg);
  --red-text:rgb(196, 31, 31);
  --linkSize:inherit;
  --textContBackground:transparent;
}

.absolute-center{
  position: absolute;
  left:50%;
}









textarea{
  font-family: inherit;
  background: inherit;
  color:inherit;
}



button{
  background: rgb(222, 215, 215);
  outline: none;
  color:black;
  font-weight: 600;
  padding: .6em 1.2em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: .8;
  font-size: 15px;
  letter-spacing: .05em;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  box-shadow: 1px 1px 2px grey;
}

button:hover:not(.no-translate-animation){
  opacity: 1;
  transform: translateY(-2px);
  box-shadow: 1px 3px 2px rgba(128, 128, 128, 0.593);
  transition:all 300ms;
}

button:active:not(.no-translate-animation){
  opacity: .9;
  transform: translateY(1px);
  box-shadow: 1px 0px 2px grey;
  transition:all 300ms;
}

button.no-translate-animation:hover{
  opacity: 1;
}
button.no-translate-animation:active{
  opacity: .9;
}

.primary{
  background: var(--button-primary-bg);
  color:white
}
.secondary{
  background: var(--button-secondary-bg);
  color:white
}
.danger{
  background: var(--button-danger-bg);
  color:white
}


.off-label{
  margin: .5rem auto;
  opacity: .4;
  font-size: 1rem;
}

section.section.isOff.editing{
  grid-row: 1 / auto;
  margin-bottom: 0px;
  min-height: 55px;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip::before {
  content: attr(data-tooltip);
  text-align: center;
  position: absolute;
  top:-5px;
  left:50%;
  transform: translate(-50%,-100%);
  padding: 1em;
  background-color: rgb(217, 214, 214);
  color: rgb(80, 78, 78);
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
  width:200px;
}


.tooltip::after {
  opacity: 0;
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgb(217, 214, 214);
}
.tooltip.down::before{
  top:unset;
  bottom:-10px;
  transform: translate(-50%, 90%);
}

.tooltip.down::after{
  top:unset;
  bottom:-10px;

}

.tooltip:hover::before,
.tooltip:focus::before,
.tooltip:hover::after,
.tooltip:focus::after 

{
  visibility: visible;
  opacity: 1;
}


.space-above{
  margin-top: 30px;
}



.tooltip.right::before{
  transform: translate(-10px, -100%);
}




.mb-2{
  margin-bottom: 2rem;
}

.mb-3{
  margin-bottom: 3rem;
}