.text-area-with-prompt-cont{
    width:90vw;
    display: flex;
    flex-direction: column;

    label{
        font-size: 1.5rem;
        margin:1em;
        width:100%;
        text-align: center;
    
    }
    textarea{
        width:100%;
        font-size: .8rem;
        min-height: 150px;
    }
}