section.table-cont{
    width: fit-content;
    margin: auto;
    border-radius: 10px;
    padding: 1rem;
    margin:unset;
    h2{
        text-align: center;
        padding-top: 30px;
    }
    table.section-submission-table{
        margin:auto;
        margin-top: 40px;
        background-color: white;
        th,td{
            padding:1rem;
        text-align: center;            
        }
        th{
            background-color: aliceblue;
        }
        tr:nth-child(odd){
            background-color: #fafafa;
        }
    }
}