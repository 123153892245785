@import "@/style/mixins.scss";
@import "@/style/variables.scss";

.freelance-section-dev-main{
    display: flex;
    .editor{
        flex-grow: 1;
        header{
            .dynamic-nav-header{
                display: none;
            }
        }
    }
    aside{
        background: $offer-white;
        width:200px;
        padding:1rem;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        height: auto;
        header{
            background:none;
            @include flex-down;
        }
        .assets-box{
            overflow: scroll;
            img{
                width:120px;
            }
        }
    }
    .back-to-dash{
        display: none;
    }
    .make-live{
        display: none;
    }
    button.open-layout-btn{
        display: none;
    }
    button.theme-btn{
        display: none;
    }
}