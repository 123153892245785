.mobile-editor-cont{
    display: flex;
    width:100%;
    justify-content: center;
    color:rgb(113, 113, 113);
    .mobile-frame{
        .image-and-text-cont{
            height: 70%;
            position:relative;
            img{
                height:100%;
                object-fit: cover;
                object-position: 50%;
                width:100%;
            }
            div.text-cont{
                width:375px;
                padding:30px;
                position: absolute;
                top: 0px;
                bottom: 0px;
                transform: translateY(0%) !important;
                display: flex;
                flex-direction: column;
            }
        }
        .slider-cont{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .custom-slider{
                width:80%;
                box-shadow: unset;
                margin-bottom: 0;
                padding:0
            }
        }
        }
    }