.max-auto-textarea{
    background: none;
    outline: none;
    overflow: hidden;
    width: 100%;
    font-size: inherit;
    font-family:inherit;
    margin:unset;
    text-align: inherit;
    padding:0;
}

.max-auto-textarea,.text-cont{
    &.size-set-small{
        font-size: clamp(.7rem,1.3vw,2rem);
    }
    &.size-set-medium{
        font-size: clamp(1rem,1.8vw,3rem);
    }
    &.size-set-large{
        font-size: clamp(1.5rem,2.2vw,4rem);
    }
}
