main.no-page-main{
    position: fixed;
    top:40%;
    left:50%;
    transform: translate(-50%,-70%);
}

main.no-page-main h1{
    margin-bottom: 2em;
    color:rgb(51, 6, 123);
}

main.no-page-main h3{
    color:rgb(52, 51, 51);
    line-height: 1.7em;
}