main.standard section.section div.arrows-cont{
    >button{
        margin:0;
        background: rgb(222, 215, 215);
        color: black;
        opacity: .8;
        outline: none;
        font-size: 15px;
        border-radius: 0;
        border:none;
    }
}



.arrows-cont button:disabled{
    color: transparent;
    background: transparent;
    box-shadow: none;
}