.audio-player-bar{
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    background:white;
    $color:rgb(58, 57, 57);
    z-index: 100;
    box-shadow: 0 -1px 8px rgba(0, 0, 0, 0.378);
    .close-button{
        position:absolute;
        top:0;
        right:0;
        font-size: 2rem;
        padding-right:1rem;
        cursor: pointer;
    }
    img{
        width:140px;
        height: 140px;

    }
    main{
        padding:10px 30px;
        progress{
            padding:1rem;
            margin-left:30px;
            width:800px;
        }
    }
    button{
        background:none;
        border-radius: 50%;
        height: 43px;
        width: 40px;
        border:5px solid $color;
        color:$color;
        svg{
            transform: translate(-5px,-1px);
        }
        svg.pause{
            transform: translate(-8px,-1px);
        }
    }
    @media screen and (max-width:500px) {
        main{
            padding: 0;
            scale:.8;
            h3{
                margin-top: 10px;
                width: 200px; /* Set the width of the element */
                overflow: hidden; /* Hide the overflow */
                white-space: nowrap; /* Prevent line breaks */
                text-overflow: ellipsis;
            }
            progress{
                width:200px;
                margin-left: 10px;
                transform: translateY(5px);
            }
            button{
                scale: .7;
            }
            div{
                transform: translateY(-10px);
                text-align: center;
            }
        }
        img{
            width:80px;
            height: 80px;
        }

    }
}


