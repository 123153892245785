@import "../../style/mixins.scss";

.header-name-cont{
    background: inherit;
    text-transform: none;
    cursor: pointer;
    input{
        text-align: center;
        @include what-you-get
    }
    textarea{
        text-align: center;
    }
}