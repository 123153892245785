.all-fields{
    display: grid;
    grid-template-columns: auto auto;
    height: fit-content;
    grid-column-gap: 20px;
    align-items: center;
    justify-items: center;
    >label{
        justify-self: flex-end;
    }
}

.all-fields .color-selector{
    margin:0 auto;
}

.all-fields>select,.all-fields>div>input{
    margin:1rem auto;
}

.all-fields select{
    width: 100%;
    max-width: 300px;
    margin-left:0;
    background: #ede9e9;
    padding:4px;
    margin: 0.5em auto;
    padding: 0.7em;
}

.all-fields .color-selector>button{
    margin: 0.5em 0;
    width: 100%;
    padding:unset;
    padding-top: .6em;
    padding-bottom: .6em;
}

.theme-editor-modal{
    top:0;
    background: white !important;
    width: 100%;
    padding:0 !important;
    border-radius: 0;
    .color-selector-cont{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin: auto;
    }
}

.theme-editor-modal .color-selector{
    margin:unset;
    
    .back-btn{
        float: right;
        transform: translate(-140px,-60px);
    }
    >button{
        width:300px;
    }
}

.flexbox-fix{
    display: none !important;
}

.flexbox-fix:nth-of-type(2){
    display: flex !important;
}

.theme-editor-modal>.modal-content{
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 6em;
    --overlayOpacity: 0;
    height: 85vh;
    overflow-y: scroll;
    grid-column-gap: 20px;
}

.theme-editor-modal .modal-content .select-cont .edit-img-cont{
    border:4px solid rgb(22, 21, 21);
    width: 90%;
    margin:unset;
}

.theme-editor-modal .select-cont>.edit-img-cont>img{
    max-height: 300px;
}

/* .theme-editor-modal .modal-content .select-cont img,.theme-editor-modal .modal-content .select-cont .overlay{
    width:400px;
} */

.theme-editor-modal .modal-content .select-cont .free-image-modal{
    top:40px;
}

.theme-editor-modal .modal-content .select-cont .modal-content{
    display: block;
    padding-top: 2em;
    height: fit-content;
    max-height: 80vh;
}
.theme-editor-modal .modal-content .select-cont .edit-img-cont .drop-area{
    width:400px;
    height: unset;
}

.theme-editor-modal .modal-content .select-cont .edit-img-cont .drop-area .dotted-div,.theme-editor-modal svg{
    display: none;
}


.theme-editor-modal .drop-area h2{
    width: unset;
    text-align: center;
    margin:1rem;
}

.theme-editor-modal .edit-img-cont.dropable>input{
    width: 80%;
}

.preview-cont{
    height: 300px;
    height: 90%;
    padding:1em 2.5em;
    margin-bottom: 40px;
    border-radius: 5px;
}

.preview-cont h1{
    font-size: 2rem;
    text-align: center;
}

.preview-cont p{
    font-size: 1.3rem;
}

.select-cont>label{width:160px;
    padding-right:20px;
}

@media screen and (max-width:1150px) {


}

@media screen and (max-width:700px) {
    .theme-editor-modal .modal-content{
        grid-template-columns: 1fr;
        padding-top:2rem;
    }

    .preview-cont{
        grid-row: 1;
        width: 200px;
        margin: auto;
        height: unset;
        width:80%;
        margin-bottom: 1rem;
    }
    .all-fields{
        grid-row: 2;
        overflow: scroll;
    }
    .theme-editor-modal .modal-content{
        padding:.5rem;
        padding-top: 0;
        grid-template-columns: 1fr;
        height: 100vh;
    }

    .all-fields .select-cont {
        flex-direction: column;
    }

    .all-fields .select-cont label{
        margin-bottom: .5em;
    }

    .all-fields .select-cont select{
        margin-left: 0;
        border: 1px solid grey;
        max-width: 320px;
    }
    .all-fields{
        grid-row: 2;
        overflow: scroll;
    }
    .preview-cont{
        grid-row: 1;
        width: unset;
        margin: 2rem 4rem 1rem 4rem ;
        height: unset;

    }
    .preview-cont h1{
        font-size: 1rem;
    }
    .preview-cont p{
        display: none;
    }

}