
.carousel-frame{
    overflow: hidden;
    .carousel{
        display: flex;
        width: fit-content;
        transition: all .5s;
        .carousel-item>*{
            width:var(--frameWidth)
        }
    }
}
.carousel-dots{
    display: flex;
    padding:10px;
    >.dot{
        margin:10px;
    }
}