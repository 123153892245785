@import "@/style/mixins.scss";

main.standard{section.section.custom-form{
    padding-bottom: 100px;

    .custom-form-main{
        header{
            display: flex;
            flex-direction: column;
            background: inherit;
            h2{
                font-size: 2rem;
            }
            p{
                font-size: 1.2rem;
                font-style: italic;
            }
        }
        
        
    }}
    .form-cont{
        position: relative;
        @include flex-down;
        >div,.description_class{
                width: 350px;
                text-align: center;
        }
    }
    form{
        margin-top: 50px;
        width:600px;
        max-width: 90vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        >div{
            font-size: 1.2rem;
            margin-top: 1rem;
            margin: auto;
            width: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: start;
            width: 80%;
            div{
                margin-bottom: 1rem;
                text-align: left;
                
            }
            input{
                box-shadow: none;
                border-radius: 0;
                border-bottom: rgba(0, 0, 0, 0.531) 3px solid;
                width: 100%;
                &:focus-visible{
                    outline: none;
                }
            }
        }
        >button{
            margin-top: 20px;
            width:150px
    }
}
}