.tiktok-grid{
    width: fit-content;
    display: grid;
    grid-template-columns:1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    margin-bottom: 50px;
    @media screen and (max-width:1100px) {
        grid-template-columns:1fr 1fr;
    }
    @media screen and (max-width:700px) {
        grid-template-columns:1fr;
    }
}

section.section.tik-tok{
    label{
        font-size: 1.3rem;
        font-family: Arial, Helvetica, sans-serif;
    }
}

.center__layout{
    .tiktok-grid{
        display: flex;
        max-width: 900px;
        width:90vw;
        flex-wrap: wrap;
        justify-content: center;
        @media screen and (min-width:600px) {
            .tiktok-embed-wrapper{
                height: 1220px;
                width:600px;
                iframe{
                    border-radius: 20px;
                    scale: 1.5;
                    transform: translateY(270px);
                }
            }
            
        }
    }
}