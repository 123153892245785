.my-pages section.my-pages-banner{
    align-items: flex-start !important;
    padding-bottom: 0.5rem !important;
    letter-spacing: .1em;
    max-width: unset !important;
    background-color: #f0f4f7 !important;
    margin-left: 0;
    padding-left: 50px;
    padding-top:2rem;
}

.my-pages-banner h1{
    font-size: 2rem;
    margin-bottom: 0;
}

.my-pages-banner h3{
    margin-top: 50px;
    font-weight: 500;
}

.my-pages{
    margin:0 !important;
    padding:0 !important;
    z-index: 100 !important;
    max-width: unset !important;
}

.modal.confirmation-modal{
    top:50%;
    transform:translate(-50%,-100%);
}


.my-pages>div{
    width: 100%;
}

section.section.cards-section{
    max-width: unset !important;
    height: unset;
    min-height: 100vh;
    background-color: #f0f4f7 !important;
    align-items: flex-start !important;
}

.cards-cont{
    display: flex;
    flex-wrap: wrap;
}

.my-pages .card{
    width: 400px;
    background:white;
    border-radius:5px
}

.my-pages .card h1{
    margin:auto .5em;
}

.add-page-btn{
    margin:1.5rem;
}

.my-pages .card{
    color: rgb(92, 89, 89);
}
.my-pages a{
    text-decoration: none;
}

.my-pages .modal{
    padding:2rem;
    padding-top:4rem;
    top:150px;
}

.my-pages .modal .close-modal-button{
    position: absolute;
    right: 0;
    color: black;
    font-size: 1.2rem;
    background: none;

}
.my-pages .modal form{
    display: flex;
    flex-direction: column;
    background: none;
}

.my-pages .modal form div{
    display: flex;
    flex-direction: column;

}

.my-pages .modal form div label{
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

.my-pages .modal form select{
    margin-bottom: 1rem;
}

.my-pages .modal form div input{
    max-width: 80vw;
    width: 350px;
}

.flex-apart{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

@media screen and (max-width:600px) {
    .my-pages .my-pages-banner{
        margin-left: 1rem;
    }
    .my-pages h1{
        font-size: 1.5rem;
    }

    .my-pages .my-pages-banner p{
        font-size: .9rem;
    }

    .my-pages section.section{
        width: unset;
        padding:1rem
    }
    .my-pages section.section.cards-section{
        padding-top: 0;
    }
    .my-pages .card{
        margin:1rem 0;
        width: 340px;
        box-sizing: border-box;
    }
    .my-pages .card h1{
        font-size: .8rem;
        margin-top: .3rem;
        margin-bottom:0;
    }
    .my-pages .card .status-cont{
    font-size: .8rem;
    margin-top:1em;
    }

    .my-pages .card .status-cont span{
        text-align: left;
        align-self: flex-end;
    }
    .create-page-modal{
        border-radius: 0;
        background: white;
        top:75px !important;
    }
    .sidebar.page-status{
        width:100%;
        padding: 0;
        height: unset;
        max-height: 80vh;
    }
    .sidebar.page-status .modal-content{
        padding:1rem;
    }
    .my-pages .share-modal{
        border-radius: 0;
        /* background: white; */
        top:75px !important;
    }
    .my-pages .share-modal .copy-cont{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .my-pages .share-modal .copy-cont button{
        margin-top: 1em;
    }
}