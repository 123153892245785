.url-edit-bar-label{
    margin-bottom:1rem
}

.url-edit-bar{
    input{
        border: 2px solid #67686736;
        font-size: .8rem;
        border-radius: 3px 0px 0px 3px;
        border-right: transparent;
        width:clamp(250px,70vw,400px);
    }
    button{
        border-radius: 0 3px 3px 0;
    }
    margin-bottom: 40px;
}