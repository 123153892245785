.section-template-builder{

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    input,textarea{
        &:hover{
            box-shadow: none;
        }
    }
    main.template-builder-main{
        flex-grow: 1;
        .stretch-frame{
            border:none;
        }
        .preview{
            height: 100%;
            overflow-Y: overlay;
            background: rgb(235, 232, 232);
            scrollbar-gutter: none;
            scroll-margin-right: 20px;
        }
        .modal{
            padding:0;
            transform: translate(-50%,-50%);
            top:50%;
            left:50%;
            background: #111111;
            color:#c8d5f3;
            &.html-css-modal{
                z-index: 1400;
            }
            .view-toggle{
                position: absolute;
                z-index: 10;
                right: 10px;
                top: 4px;
                cursor: pointer;
                &:hover{
                    scale:1.1;
                }
            }



            .move-bar{
                cursor: pointer;
                padding-left: 20px;
            }
            .close-modal-button{
                display: none;
            }
            button.expand-btn{
                position: absolute;
                left:50%;
                top:0;
                transform: translate(-50%,10%);
                padding:0;
            }
        }
        aside{
            border-right: 3px solid rgb(48, 47, 47);
            // box-shadow: 2px 2px 3px rgba(117, 117, 117, 0.63);
            .elements-editor{
                background: rgb(30, 31, 32);
                border-bottom: 4px solid black;
                position: relative;
                height: 300px;
                button.collapse-btn{
                    position: absolute;
                    padding:0;
                    top:0;
                    left:50%;
                    transform: translate(-50%,-110%);
                }
                h3{
                    margin-bottom: 1em;
                }
                textarea{
                    font-size: .9rem;
                    width: 100%;
                    height:300px;
                }
            }
            .css-editor-cont{
                height: 50%;
                // background: wheat;
                font-size: .9rem;
            overflow-y: scroll;
            div.table-wrapper{
                max-height: 200px;
                .wide-narrow-btn{
                    width: calc(100% - 1px);
                    border-radius: 0;
                    font-size: .8rem;
                    padding: 0.2em;
                    background:purple;
                    color:white;
                }
                table{
                    max-height: 100px;
                th{
                    padding-bottom: .5rem;
                }
                tbody{
                    max-height: 100px;
                    overflow: scroll;
                    tr{
                        td{
                            padding: .2rem;
                            input{
                                font-size: .8rem !important;
                                border-radius: 0px;
                                width:100%;
                                padding:0.05rem;
                                margin-bottom: 0rem;   
                                color:inherit;
                                background-color:rgb(30, 31, 32);
                                &.selector-input{
                                    width:100px;
                                }
                                &.property-input{
                                    width:120px;
                                }
                            
                            }
                            }
                        }
                    }
                }
            }
            
        }
    }
    }
    footer{
        background: grey;
        display: flex;
        justify-content: center;
        button{
            margin:1rem;
        }
    }
}