.card-elements-cont{
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin:auto;
    grid-gap:10px;
    background:rgb(247, 247, 247);
    padding:2rem;
    border-radius: 10px;
    .stripe-element-cont{
        margin-bottom: .5rem;
        &.number{
            grid-column: 1/3;
        }
    }
    .stripe-sign{
        margin-top: -5px;
display: flex;
    justify-content: center;
                    grid-column: 1/3;

    align-items: center;
    margin-bottom: 10px;
        svg{
            margin-left: 20px;
            margin-top: -20px;
            margin-bottom: -20px;
        }
    }
    .btn-cont{
        display: flex;
    justify-content: center;
                    grid-column: 1/3;

    align-items: center;
    }
    .StripeElement{
    padding: 0.5em;
    background: #eeeeee;
    border-radius: 5px;
    box-shadow: #0000001c 0px 2px;
    margin-top: 3px;    

    }
    
}


.pm-card input{
    height: 200px !important;
}

.payment-methods button{
    margin-top:5px;
    margin-right:10px;
}