.modal.temp-popup{
    top: 40%;
    font-size: 1.5rem;
    padding-top:unset;
    padding:4em;
    font-weight: bold;
}


.temp-popup button{
    display: none;

}