.quotation-section{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

section.section.testimonials{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: unset;
    align-items: flex-start;
}

.testimonials .cont{
    width: 350px;
    background: rgb(238, 233, 233);
    margin: 2rem;
    padding: 1rem;
    position: relative;
    margin-top: 100px;
    border-radius: 10px;
    min-width: 200px;
    padding-top:2.5rem
}

.testimonials .img {
    position: absolute;
    height: 100px;
    width:100px;
    border-radius: 50%;
    top: 0;
    left: 50%;
    transform: translate(-50%,-50%);
    box-shadow: 0 0 3px black;
}

.testimonials .quote{
    font-size: 1.5rem;
    background: transparent;
    font-family: inherit;
    font-weight: 600;
    color:inherit;
}

.testimonials .quote::before,.testimonials .quote::after{
    content: '"';
}

.testimonials .name-and-title{
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonials .name-and-title>input{
    max-width: 200px;
}

.testimonials .name{
    color: #ab9625;
    font-size: 1.4rem;
    font-weight: 600;
    background: transparent;
    display: inline;

    /* width: 300px; */
    padding: 0;
}

.testimonials .title{
    margin-left: 10px;
    background: transparent;
    display: inline;

    /* width: 200px; */

}

.testimonials input.title{
    font-size: .8rem;
    padding:0
}

.testimonials .edit-img-cont{

    max-width: 100px;
    max-height: 300px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-50%);
}

.testimonials .delete-circle{
    transform:translate(50%, -150%);
    z-index: 100;
    top:0;
    position: absolute;
}

.testimonials .edit-img-cont input{
    position: absolute;
    left: 130px;
    top: -60px;
}

.testimonials .drop-area {
    width:120px !important;
    height: 120px !important;
}

.testimonials .drop-area h2{
    font-size: .9rem;
}
.testimonials .drop-area .dotted-div{
    display: none;
}
.testimonials .drop-area ~ input{
    position: absolute;
    left: 130px;
    top: -20px;
    width: 300px;
}

.testimonials .drop-area ~ .open-file-modal-btn{
    position: absolute;
    background: #d3c9b8;
    margin-top: 1rem;
    border-radius: 10px;
    left: 130px;
    width: 140px;
    top: 30px;
    opacity: 1;
}

.testimonials  .edit-img-cont input:not(~ .drop-area){
    display: none;
}

.testimonials .drop-area svg{
    display: none;
}