.field-box{
    background:#f9f9f9;
    padding:20px;
    margin:auto;
    border-radius: .5rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 90vw;
    width:768px;
    margin-top: 40px;
    input,select{
            box-shadow: unset;

    }
    .prompt-cont{
        input{
            width:400px;
            max-width: 90%;
        }
    }
    .dropdown-cont{
        display: flex;
        justify-content: flex-end;
        select{
            align-self: right;
        }
    }

    footer{
        grid-column: 1/3;
        hr{
            margin:10px;
            margin-top:30px;
        }
        >div{
            columns:1/3;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .toggle-cont{
            margin-left:1rem;
            border-left: 2px solid grey;
            padding-left:1rem;
        }
    }

    .delete-cont{
        cursor: pointer;
    }
    

}