@import "@/style/mixins.scss";

div.token-purchase-stripe{
    padding:3rem;
    div.payment-methods{

        display: flex;
        flex-direction: column;
        padding: 30px;
        button{
            align-self: center;
        }
    }
    .purchase-btn-cont{
        button{
            margin: auto;
            display: block;
        }
    }
    button.center-btn{
        margin: auto;
        display: block;
    }
}