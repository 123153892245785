div.dot{
    width:20px;
    height: 20px;
    border:2px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .filler{
        width:10px;
        height: 10px;
        background: black;
        border-radius: 50%;
    }

}