.add-css-text-cont{
    padding:1rem;
    textarea{
        width:570px;
        font-size: .8rem;
        margin-bottom: 0;
        box-shadow: none;
        border-radius: 0;
        border:1px solid grey;
    }
    button{
        width: calc(100% - 1px);
        border-radius: 0;
        font-size: .8rem;
        padding: 0.2em;
    }
}