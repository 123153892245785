.links-cont{
    display: flex;
    flex-direction: column;
    
}


.link-cont{
    position: relative;
    background: none;
    padding:1rem;
    margin:.5rem;
    display: flex;
    flex-wrap: wrap;
    max-width: 80vw;
    justify-content: center;
}

.input-cont{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.link-cont>div{
    max-width: 80vw;
}

.link-cont .label-cont{
    width:100px;
}


.link-cont.hide{
    display: none;
}

.link-cont input{
    margin-left:10px;
    margin-right:30px;
    margin-bottom: 0;
}

.links-cont a{
    font-size: clamp(1.25rem, 3vw, 2.3rem);
    color:inherit;
    margin-top:.5em;
    margin-bottom: 1em;
    opacity: .75;
    text-decoration: none;
    position: relative;
}

.links-cont a:hover{
    opacity: 1;
}

.links-cont a::after{
    content: "";
    display: block;
    width: 0%;
    height: 4px;
    background: rgb(241, 239, 239);
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    transition: width 600ms;
}

.links-cont a:hover::after{
    width: 100%;
    height: 4px;
}

@media screen and (max-width:500px) {
    .input-cont{
        flex-direction: column;
        align-items: flex-start;
    }
    .label-cont{
        margin-bottom: 10px;
    }
    .input-cont input{
        width:70vw
    }
}