.hex-cont{
    padding-top:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    input{
        margin:10px;
        padding: 0.3em;
        width:70px;
        text-align: center;
        font-size: 1.1rem;
        text-transform: uppercase;
    }
}