.message-center{
    height: 100vh;
    width: 100vw;
    background: black;
    display: flex;
    aside{
        background: green;
        width: 400px;
    }
    main{
        background: orange;
        flex-grow: 1;
    }
}