.section.socials-section .section-heading{
    margin-bottom: 3.3rem;
}


.socials{
    display: flex;
    max-width: 80vw;
    flex-wrap: wrap;
    justify-content: center;
    a{
        padding:1rem 1rem;
        color:inherit;
        opacity: .7;
        :hover{
            opacity: 1;
        }
        .social-icon{
            cursor: pointer;
        }
    }
    a:hover{
        opacity: 1;
    }
}



img.social-icon{
    color:inherit;
    width: 50px;
}

@media screen and (max-width:600px) {
    .social-icon{
        height: 10vw;
}}