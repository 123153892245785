.blog-entry {
    width: 80%;
    max-width: 700px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 4px 7px rgb(0 0 0 / 40%);
    border-radius: 10px;
    position: relative;
    img{
      float: left;
      width: 50%;
    }
    header{
        display: grid;
        grid-template-columns: auto auto;
        background: unset;
        position: unset;
        margin-bottom: 2em;
        h2 {
          font-size: 1.5em;
          margin: 20px 0;
          margin-bottom: 0;
          margin-bottom: .5em;
        }
        h5{
          opacity: .6;
          font-style: italic;
          font-size: 1rem;
        }
    }
    main{
        height: 250px;
        overflow: hidden;
        font-size: 1.2rem;
        position: relative;
        // &.preview{
        //   img{
        //     width: 100%;
        //     max-width: unset !important;
        //   }
        // }
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100px;
          background-image: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
          pointer-events: none;
          opacity: 1;
          transition: opacity 0.3s;
        }

        li{
          margin-bottom: 1rem;
        }
        p {
            font-size: 1.2em;
            line-height: 1.5;
            margin: 20px 0;
            overflow: hidden;
            height: 120px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            margin-bottom: 1em;
        }
        &.expanded {
          height: auto;
          &::after {
            opacity: 0;
          }
      
          p {
            height: unset;
            overflow: unset;
            display: block;
          }
        }
    }
        
    .read-more {
      font-size: 1.2em;
      color: #007bff;
      cursor: pointer;
      margin: 20px 0;
      display: block;
      text-align: right;
      @media screen and (max-width:800px) {
        font-size: 1rem;
      }
    }
    @media screen and (max-width:800px) {
        header{
          display: flex;
          flex-direction: column;
          h2{
            text-align: center;
            margin-bottom: 1rem;
          }
        }
        main{
          height: 200px;
          font-size: .9rem !important;
          p{
            font-size: .9rem !important;
            height: 180px;
          }
        }
    }

  }
  