main.standard section.section.button-with-text{
    background:#3e3d3b;
    min-height: 130px;
    padding:30px 120px;
    color:white;
    padding:20px 200px;
    @media screen and (max-width:500px){
    padding:unset;
    padding-bottom:30px;
    }
    .overlay{
        opacity: .2;
        background: red;
    }
    .content{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width:100%;
        @media screen and (max-width:500px){
            width: 80vw;
            >div,.text_class{
                width:unset !important
            }
        }
        .text_class{
            font-size: 2rem;
            width:40%;
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: center;
            textarea{
                width:100%;
                height: fit-content;
            }
        }
        button{
            color:inherit;
            font-size: 1.3rem;            
            font-family: inherit;
            position: relative;
        }
        .e-button-cont{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        textarea,input{
            text-align: center;
        }
    }
}