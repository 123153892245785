.floater{
    z-index: 100000;
    position: fixed;
    bottom:50px;
    right:50px;
    padding:0;
    background: rgba(231, 243, 251, 0.478);
    cursor: pointer;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1.6rem;
    border:5px solid rgba(255, 255, 255, 0.42);
    color:grey;
    @media screen and (max-width:500px) {
        right:unset;
        left:50%;
        transform: translateX(-50%);
        text-align: center;
        background: rgba(231, 243, 251, 0.931);
        border:5px solid rgba(95, 94, 94, 0.9);

    }
    &:hover{
        color:black;
        background: rgba(231, 243, 251, 0.7);

    }
    a{
        color:inherit;
        text-decoration: none;
    }
}