.splash .hero{
    width: 100% !important;

    
}

.splash .overlay{
    opacity: 0;
}

.splash .hero>section .content{
    padding-left: unset;
    display: flex;
    align-items: center;
    color:white;
}
.splash .hero>section .content h1{
    font-size: 5rem;
    margin-bottom: 0.2em;
    color:#fcfcfc;
}

.splash .hero>section .content h3{
    margin-bottom: 5rem;
    color:white;
}

.splash .hero>section .content>button{
    margin-top: 30px;
    background: rgb(239, 231, 6);
    font-size: 1.5rem;
    color: rgb(51, 4, 54);
    opacity: .9;
    border-radius: 2em;
}


@media screen and (max-width:600px) {
    /* main .hero {
        height: 350px;
    }
    main .hero h1 {
        font-size: 2.5rem !important;
    } */
    .mid h1{
        font-size: 1.5rem !important;
        margin: 0;
    }
}