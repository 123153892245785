
.cantarell__font{
  font-family: "Cantarell",Arial, Helvetica, sans-serif;
}


.prata__font{
  font-family: "Prata", serif;
}

.georgia__font{
    font-family: Georgia, 'Times New Roman', Times, serif;
  }

  .figtree__font{
    font-family: "Figtree", sans-serif;
  }
  
  .monospace__font{
    font-family: monospace;
  }
  
  .arial__font{
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .verdana__font{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .ultra__font{
    font-family: "Ultra", sans-serif;
  }
  
  .tahoma__font{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .trebuchet__font{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  
  .times__font{
    font-family: 'Times New Roman', Times, serif;
  }
  
  .lucida__font{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  
  .courier__font{
    font-family: 'Courier New', Courier, monospace;
  }
  
  .impact__font{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  
  .roboto__font {
    font-family: 'Roboto', sans-serif;
  }
  .open-sans__font {
    font-family: 'Open Sans', sans-serif;
  }
  .montserrat__font {
    font-family: 'Montserrat', sans-serif;
  }
  .lato__font {
    font-family: 'Lato', sans-serif;
  }
  .oswald__font {
    font-family: 'Oswald', sans-serif;
  }
  .raleway__font {
    font-family: 'Raleway', sans-serif;
  }
  .source-sans-pro__font {
    font-family: 'Source Sans Pro', sans-serif;
  }
  .pt-sans__font {
    font-family: 'PT Sans', sans-serif;
  }
  .ubuntu__font {
    font-family: 'Ubuntu', sans-serif;
  }
  .slabo-27px__font {
    font-family: 'Slabo 27px', serif;
  }
  .playfair-display__font {
    font-family: 'Playfair Display', serif;
  }
  .lora__font {
    font-family: 'Lora', serif;
  }
  .merriweather__font {
    font-family: 'Merriweather', serif;
  }
  .noto-sans__font {
    font-family: 'Noto Sans', sans-serif;
  }
  .pt-serif__font {
    font-family: 'PT Serif', serif;
  }
  .crimson-text__font {
    font-family: 'Crimson Text', serif;
  }
  .josefin-sans__font {
    font-family: 'Josefin Sans', sans-serif;
  }
  .bitter__font {
    font-family: 'Bitter', serif;
  }
  .arimo__font {
    font-family: 'Arimo', sans-serif;
  }
  .vollkorn__font {
    font-family: 'Vollkorn', serif;
  }

  
.in-frame .typing-effect {
    animation: typing 10s steps(40, end);
    white-space: nowrap;
    overflow: hidden;
    /* width: 100%; */
  }
  
  @keyframes typing {
    from { width: 0; }
  }
  
  .inframe.slide-up__effect h1,.inframe.slide-up__effect .h1,
  .inframe.slide-up__effect p,.inframe.slide-up__effect textarea.p
  {
    animation: slide-up 1s ease-in-out;
    transform-origin: bottom;
    transform: translateX(0%);
    opacity: 1;
  }
  
  @keyframes slide-up {
    0% {
        transform: translateY(20%);
        opacity: 0;
    }
    100% {
        transform: translateY(0%);
        opacity: 1;
    }
  }
  
  .inframe .slide-left {
    animation: slide-left 1s ease-in-out;
    transform-origin: bottom;
    transform: translateX(0%);
    opacity: 1;
  }
  
  .inframe.slide-left__effect h1,.inframe.slide-left__effect .h1,
  .inframe.slide-left__effect p,.inframe.slide-left__effect textarea.p
  {
    animation: slide-left 1s ease-in-out;
    transform-origin: bottom;
    transform: translateX(0%);
    opacity: 1;
  }
  
  @keyframes slide-left {
    0% {
        transform: translateX(5vw);
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
  }
  
  .inframe.grow__effect h1,.inframe.grow__effect .h1,
  .inframe.grow__effect p,.inframe.grow__effect textarea.p
  {
    animation: grow 1s ease-in-out;
    opacity: 1;
  }
  
  @keyframes grow {
    0% {
        scale: .8;
        opacity: 0;
    }
    90%{
      scale:1.05
    }
    100% {
        scale:1;
        transform: translateX(0%);
    }
  }
  
  .slide-right {
    animation: slide-right 2s ease-in-out;
    transform-origin: bottom;
    transform: translateX(0%);
  }
  
  @keyframes slide-right {
    0% {
        transform: translateX(-100vw);
    }
    100% {
        transform: translateX(0%);
    }
  }
  
  .flip-down {
    transform: rotateX(90deg);
    transition: transform 500ms;
  }
  
  .flip-down.visible{
    transform: rotateX(0deg);
  }
  
  
  .bg-none,.off__backgroundState{
    background: none !important;
  }
  
  .image__background{
    background:var(--backgroundImage);
    background-attachment: fixed;
  }

  .underline__linkHover{
    a{
      position: relative;
    }
    a:after{
      position: absolute;
      bottom:0;
      left:50%;
      transform: translateX(-50%);
      content: "";
      width: 0;
      height: 4px;
      background: var(--underlineColor);
    }
    a:hover::after{
      width: 100%;
      transition: all 500ms;
    }
  }

section.section .heading__class{
    color:var(--headingColor);
}

.inframe.fade-in__animation>*,.inframe .fade-in{
  text-shadow: 2px 1px 0px #888;
  opacity: 1;
  animation: fadeIn 1s ease-in-out forwards;
} 

@keyframes fadeIn {
  from {
    opacity: .6;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}