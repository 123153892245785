section.section.how-to{
    display: grid;
    color:rgb(67, 64, 88);
    justify-items: center;
    align-items: flex-start;
    background:white;
    padding-top:80px !important;
    padding-bottom:80px !important;
    >div{
        margin:30px;
    }
    h1{
        font-size:4.5rem;
        margin-top:0;
        /* This is so it takes two lines */
        max-width: 500px;
        margin-bottom:.5em;
    }
    p{
        font-size:1.5rem;
    }
    .list-cont{
      max-width: 75% !important;
      ol{
          margin-top:20px;
          font-size:1.5rem;
          max-width: 600px;
          li{
              margin-bottom: 1em;
          }
      }
    }
    button{
        margin: 0.5rem;
        border-radius: 1.5em;
        font-size: 1.3rem;
        color:white;
        background-color: rgb(36, 21, 29);
    }
    .grid{
        grid-template-columns: 1fr 1fr;
        display: grid;
        justify-items: center;
        align-items: flex-start;
    }
}

.splash section.section.faq{
    background: #f4f4f472;
    padding:150px 70px;
    aside{
        width:80vw;
        max-width: 600px;
        margin:2rem;
    }
    .grid{
        display: grid;
        justify-items: center;
        align-items: flex-start;
        margin-top:70px;
        margin-bottom: 20px;
        grid-template-columns: 2fr 1fr;
    }
    .faq-title{
        display: flex;
        width: 100%;
        justify-content: center;
        h1{
            rotate:90deg;
            font-size: 10rem;
        }
    }
    p{
      font-size: 1.2rem;
    }
}

.splash section.section.samples{
  h2{
    font-size: clamp(1.5rem,8vw,2.7rem);
    margin-bottom: 1em;
  }
}


@media screen and (max-width: 1050px) {
    .splash section.section.faq {
      grid-template-columns: 3fr 1fr;
      h1 {
        font-size: 3rem;
      }
    }
  }
  
  @media screen and (max-width: 800px) {
    section.section.how-to {
      padding: unset !important;
      padding-top: 50px !important;
    }
    section.section.how-to .grid,
    .splash .grid {
      grid-template-columns: 1fr;
      margin: auto;
      padding: unset !important;
      margin: 0 !important;
      width: 100vw;
      padding-bottom: 40px !important;
      * {
        max-width: 100%;
      }
      h1 {
        padding: 5vw;
        width: 90vw;
        text-align: center;
        margin-bottom: 0 !important;
      }
      & > div:first-child {
        display: flex;
        flex-direction: column;
        text-align: center;
      }
      ol {
        margin-top: 40px !important;
        font-size: 1rem !important;
        padding-right: 20px;
      }
    }
    .how-to a {
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      text-decoration: none;
      margin-top: 40px;
    }
    .splash section.section.faq{
      padding:20px 50px;
    }
    .splash section.section.faq .grid {
      display: flex;
      flex-direction: column-reverse;
      h1 {
        rotate: 0deg;
        margin-top: 40px;
      }
    }
    .faqs-aside {
      margin: auto;
      margin-top: 40px;
    }
    .expander main.expanded {
      padding: 20px 0;
      height: fit-content !important;
    }
    .splash section.section.mid {
      padding: 20px !important;
    }
    .section.section.how-to h1 {
      font-size: 2.5rem !important;
      margin-top: 0;
      max-width: 500px;
      margin-bottom: 0.5em;
    }
    .expander p {
      margin-top: 1em;
      font-size: 1.2rem;
    }
  }
  