@import "@/style/mixins.scss";

.owners-nav{
    width: 100%;
    display: flex;
    padding:.5rem;
    justify-content: space-between;
    align-items: center;
    z-index: 3500;
    box-sizing: border-box;
    >button,>a>button,.add-section-cont>button,>div>button,.blue{
        @include blue-button;
        margin:.5rem;
    }
    >div{
        display: flex;
    }
    .owners-header-right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        a{
            height: 30px;
            margin-left: 10px;
        }
        svg{
            cursor: pointer;
            color: rgb(105, 23, 168);
        }
        button{
            margin-right: 20px;
        }
    }
    .edit-button{
        @include pulse;
    }
}