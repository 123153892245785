h3.h3{
    width:80vw;
    input{
        width: 100%;
        font-family: inherit;
        background: transparent;
        font-size: inherit;
        font-style: inherit;
        color:inherit;
    }
}