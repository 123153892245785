
section.card__event .event{
    background:#dddddd42;
    border-radius: 10px;
}


.event-cont{
    position: relative;
    display: flex;
    align-items: center;
    .modal-overlay{
        .modal{
            color:black;
            top:50%;
            transform: translate(-50%,-50%);
            .close-modal-button{
                display: none;
            }
            .save-btn{
                margin-bottom: 1rem;
                float: right;
                margin-right: 1rem;
            }
        }
    }
}

.event{
    border-top: 3px solid rgba(128, 128, 128, 0.478);
    padding:1rem;
    display: grid;
    grid-template-columns: 1.5fr 3fr;
    justify-items: end;
    max-width: 600px;
    width:80vw;
    position: relative;
    font-size: clamp(12px,3vw,18px)
    first-of-type{
        margin-top: 0px;
    }
    >*:nth-child(even){
        justify-self: start;
        color:inherit;
        width: 150px;
    }
    >*:nth-child(odd),.event *:nth-child(odd)>*{
        justify-self: end;
        color:inherit;
        width: 100%;
        text-align: right;
    }
    .react-datepicker__tab-loop{
        // display: none;
        color:inherit;
    }
    .react-datepicker{
        button{
            width:20px !important;
            box-shadow: none;
        }
    }
    button{
        width:unset !important;
    }
    
    h2{
        width: unset;
        justify-self: start;
        align-self: center;
    }
    .input-cont{
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-bottom: 0;
        input{
            margin-top:0;
            width:100%;
            padding:.5em;
        }
    }

    input{
        background: rgba(128, 128, 128, 0.133);
        padding:.3em 0;
        color: inherit;
    
    }
    .two-col{
        grid-column: 1 / 3;
        width:100%;
        text-align: left;
        opacity: 1;
        text-transform: uppercase;
    }
    h2,h3,h4{
        margin-bottom: .1rem;
    }
    .date-cont{
        width: 160px;
    overflow: clip;
    }

}



section.section.events.normal__eventDisplay a>h4{
    text-decoration: underline;
    opacity: .7;
}





@media screen and (max-width:550px) {
    .event>*{
        font-size: 3.5vw;
        margin-top: .5rem;
    }
    .event a>h4{
        margin:0
    }
    .event input{
        font-size: 3.5vw;
    }
}

.one-line-event{
    margin-bottom: 1.5em;
}

.wide-with-button{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width:80vw;
    text-align: center;
    margin-bottom: 1.5em;
    align-items: center;
    button{
        color:black;
        background: white;
        border:solid 2px black;
        border-radius: 0;
    }
    @media screen and (max-width:500px) {
        grid-template-columns: 1fr;
        grid-row-gap:1.2em;
        scale:.9;
    }

}

.date-on-side{
    display:grid;
    grid-template-columns: 1fr 8fr;
    width:800px;
    max-width: 80vw;
    margin-bottom: 10px;
    background: rgba(113, 113, 113, 0.082) ;
    padding:20px;
    @media screen and (max-width:500px) {
        grid-template-columns: 1fr;
        padding-left: 0;
        div.left{
            display: none;
        }
    }
    .left{
        border-right: 3px solid black;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height:100px;
        .month{
            font-weight: bolder;
            margin-bottom: .3em;
        }
        .date{
            font-weight: bolder;
            font-size: 1.3rem;
        }

    }
    .right{
        padding-left: 2rem;
        h5.location{
            font-weight: lighter;
            font-style: italic;
            padding: .5em;
            padding-left: 0;
        }
        div{
            margin-top: 2rem;
        }
    }
}