.options-box{
    grid-column: 1/3;
    font-size: 1rem;
    .edit-option-line{
        display: flex;
        width:100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .7rem;
        .ordinal{
            margin-right: 20px;
        }
        input{
            margin-bottom: unset;
            box-shadow: none;
            background:inherit;
            padding:.2rem;
            border-radius: 0;
            font-size: inherit;
            &:hover{
                border-bottom: 2px solid grey;
            }
        }
    }
    button{
        padding:5px 10px;
    }
}