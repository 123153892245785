main{
    background-size: cover !important;
}


.public-standard-footer{
    background-color: rgb(63, 61, 61);
    color: white;
    text-align: center;
    padding: 1rem;
}

.public-standard-footer h3{
    color: white;
    margin:0
}

.public-standard-footer h3 a{
    margin-left:1rem;
    color:#bdbdbd;
}

.grid{
    display: grid;
}

.standard .section.hero{
    max-height: unset;
    width:100%;
    position: relative;
}

.splash section.section{
    padding:0;
    width:unset !important
}

main.standard p,main.standard p .p{
    line-height: 1.4em;
    font-size: clamp(1rem,2vw,3rem)
}

main.standard p input.p{
    text-align: center;
}



@media screen and (max-width:500px) {
    main.grid section .toggle-on-cont{
        transform: translateY(0);
    }
    main.grid::before{
        content: "" !important;
    }
}

.floater-content{
    padding:1rem;
    font-size: 1rem;
}


.instructions-placeholder p{
    font-size: clamp(1rem,4vw,1.7rem);
    margin-top: 1em;
}

