.color-selector-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    .back-btn{
        padding: 0.2rem;
        align-self: flex-start;
        margin-bottom: 10px;
    }
    >div>button{
        width: 100px;
        height: -moz-fit-content;
        height: fit-content;
        margin: unset;
        font-size: .8rem;
        padding: 5px;
        height: 30px;
    }
    .slider-cont{
        .custom-slider{
            margin:0;
            padding:0;
        }
    }
}