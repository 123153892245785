table.cost-calc-table{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 3fr 1fr;
        td{
            padding:.3em;
            &:nth-child(odd){
                font-weight: bold;
            }
            &:nth-child(even){
                text-align: right;
            }
        }
        hr{
            height: 2px;
            background-color: black;
            margin:.5em 0;
            grid-column: 1/3;
        }
}