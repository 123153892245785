.auto-textarea{
    background: none;
    outline: none;
    overflow: hidden;
    width: 100%;
    font-size: inherit;
    font-family:inherit;
    margin:unset;
    position: relative;
}


.auto-textarea-cont{
    .floating-notice{
        position: absolute;
        right:50px;
        top:50px;
    }
}

.left__textHor{
    .over-wide{
        width:150vw;
    }
}
.right__textHor{
    .over-wide{
        width:150vw;
        transform: translateX(-65vw);
    }
}
.center__textHor{
    .over-wide{
        width:150vw;
        transform: translateX(-35vw);
    }
}