.chrome-picker{
    margin-top:18px;
    >div:nth-child(2){
        background:black;
        height: 60px;
        >div{
            display: none !important;
        }
        >div:first-child{
            display: unset !important;
            >div:first-child{
                display: none;
            }
            .hue-horizontal{
                height:34px !important;
                div{
                    display: block !important;
                }
            }
            >div{
                div:nth-child(2){
                    display: none;
                }
            }
            
        }
    }
}