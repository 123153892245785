/* We will need to find a good way to organize this */

.standard{
    --header-bg:rgb(231, 231, 243);
    --primary-section-bg:rgb(232, 242, 245);
    --alternating-section-bg:grey;
    color:rgb(113, 88, 88);

}

.greyscale{
    --header-bg:rgb(255, 255, 255);
    --primary-section-bg:rgb(225, 225, 225);
    --alternating-section-bg:rgb(209, 209, 209);
}