main.standard section.section.video-with-text{
    div.content{
        display: flex;
        padding:30px;
        @media screen and (max-width:800px) {
            flex-direction: column;
            align-items: center;
            padding: 0;
            div.left{
                padding-top: 30px;
                width: 90vw;
                margin-bottom: 0;
                margin-top: 0;
                div.heading_class{
                    font-size: 1.5rem;
                }
                div.text_class{
                    font-size: 1.3rem;
                    margin-bottom: 0;
                }
            }
        }
        .left{
            width:40vw;
            margin:2rem;
            .heading_class{
                font-size: 3rem;
                margin-bottom: .5em;
                textarea{
                    width: 100%;
                }
            }
            .text_class{
                font-size: 1.4rem;
                margin-bottom: 1em;
                width:100%;
                textarea{
                    width:100%;
                }
            }
        }
        .right{
            margin:2rem;
            .centerer{
                width:30vw;
                max-width:600px;
                min-height: unset;
            }
            input{
                width:100%
            }
        }
    }
    @media screen and (max-width:800px) {
        padding-bottom: 0;
        div.content{
            div.right{
                margin-bottom: 0;
            }
        }
    }
}